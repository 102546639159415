<template>
  <v-select
    class="select-field"
    :options="choices"
    v-model="selectedValue"
  >
  </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
  components: {vSelect},
  props: {
    choices: {
      type: Array,
      default: []
    },
    value: String,
  },
  computed: {
    selectedValue: {
      get() {
        return this.value
      },
      set(newValue) {
        console.log(newValue)
        this.$emit('update:value', newValue)
      }
    }
  },

}
</script>