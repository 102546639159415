<template>
    <div class="custom-select" :tabindex="tabindex" @blur="handleBlur">
        <div class="select" :class="{ open: open }" @click="open = !open">
            {{ selected || placeholder }}
        </div>
        <div class="dropdown items" :class="{ selectHide: !open }">
            <input @input.prevent="handleSearch" class="search" placeholder="Search" type="text">
            <div v-if="options.length" class="dropdown-list">
                <div
                    class="dropdown_item"
                    v-for="(option, i) in options"
                    :key="i"
                    @click.prevent="selectOption(option)"
                >
                    {{ option }}
                </div>
            </div>
            <div v-else class="dropdown_item">Field not found</div>
            <div v-if="textButton" @click="$emit('newOption')" class="fast-add-option"><span></span> {{ textButton }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false
        },
        placeholder: {
           type: String,
           default: ''
        },
        textButton: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            open: false,
            selected: ''
        };
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        value(newValue) {
            this.selected = newValue;
        }
    },
    methods: {
        handleSearch(event) {
            const searchValue = event.target.value;
            this.$emit('search', searchValue);
        },
        handleBlur(event) {
            let isSearchInput = event.relatedTarget && event.relatedTarget.classList.contains('search');
            if (!isSearchInput) {
                this.open = false;
                isSearchInput = null;
            }
        },
        selectOption(option) {
            this.selected = option;
            this.open = false;
            this.$emit('input', option);
        }
    }
};
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.selectHide
    display none !important

.no-icon
    div:first-child
        padding-left: 16px !important

.custom-select
    position relative


    .select
        display flex
        align-items center
        width: 100%
        height: 44px
        padding-left: 60px
        color #5A5A5A
        font-size: 16px
        border-radius 4px
        border 1px solid
        border-color #B6BED8
        outline none
        cursor: pointer
        background none
        transition 0.2s
        position relative

        &:focus
        &:hover
            border 1px solid $blue

            .select:after
                background $blue !important

    .dropdown
        width: 100%
        display flex
        flex-direction column
        border 1px solid #B6BED8
        border-radius 4px
        position absolute
        top: 48px
        left: 0
        margin: 0 !important
        background #FCFCFD
        z-index 10
        overflow hidden

        &-list
            max-height 176px
            height auto
            overflow-y auto

        .fast-add-option
            padding: 14px
            cursor pointer
            color #517BFB
            font-size: 14px
            display flex
            align-items center
            margin: 0 !important

            span
                width: 12px
                height: 12px
                position relative
                display block
                margin-right: 12px

                &:before
                &:after
                    content ''
                    border-radius 6px
                    width: 2px
                    height: 12px
                    position absolute
                    background #517BFB

                &:after
                    transform rotate(90deg) translateY(-50%)
                    left: -1px
                    top: 0

                &:before
                    left: 50%
                    transform translateX(-50%)
                    top: 0

        .search
            background white
            border none
            border-bottom 1px solid #B6BED8 !important
            outline none
            padding-left: 8px
            height: 30px

        &_item
            margin: 0 !important
            padding: 14px
            cursor pointer
            transition 0.2s

            &:hover
                background #F7F8FD


</style>


