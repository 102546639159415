import Vue from "vue";
import router from "@/router";

export default {
    state: {
        user: null
    },
    mutations: {
        setUser(state, data) {
            state.user = data
        }
    },
    actions: {
        async fetchUser(context) {
            try {
                const token = localStorage.getItem('auth_token')
                if (!token) return
                const response = await Vue.prototype.$services.authenticate.callMethod({token})
                const result = await response.json()
                context.commit('setUser', result)
            } catch (e) {
                console.error('[ERROR]: Authorization token has expired')
                localStorage.removeItem('auth_token')
                router.push({name: 'auth_page'})
            }
        }
    },
    getters: {
        getUser: state => state.user
    },
    namespaced: true
}