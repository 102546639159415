export default {
  data() {
    return {
      isPopupTypeOfTask: false,
      type_of_task: {
        value: ''
      },
      type_of_tasks: [],
      customersForDropdown: [],
      searchCustomersField: '',
      countersInCustomer: [],
      newTask: {
        user_id: '',
        customer_id: '',
        type_of_task: '',
        deadline: '',
        counter_number: '',
        status: 'Opened'
      },
      statusOptions: [
        {
          label: 'Geöffnet',
          index: 'Opened'
        },
        {
          label: 'Abgeschlossen',
          index: 'Completed'
        },
        {
          label: 'Abgesagt',
          index: 'Cancelled'
        },
      ],
    }
  },
  computed: {
    countersInCustomerComputed: {
      get() {
        let array = []
        for (const counter in this.countersInCustomer) {
          array.push(`${this.countersInCustomer[counter].counters_type} ${this.countersInCustomer[counter].counters_number}`)
        }
        return array
      },
      set(value) {
        this.countersInCustomer = value
      }
    },
    userOptions() {
        const array = []
        for (const user in this.users) {
            const object = {}
            object.index = this.users[user].id
            object.label = `${this.users[user].first_name} ${this.users[user].last_name}`
            array.push(object)
        }
        return array
    }
  },
  methods: {
    async createTask() {
      await this.initEventTask('createTask', 'create')
    },
    async editTask() {
      await this.initEventTask('editTask', 'edit')
    },
    async initEventTask(event, typeMessage) {
      if (this.$v.newTask.$invalid) {
        this.$v.$touch()
        return
      } else {
        try {
          this.isPopupTask = false
          let res = await this.$services[event].callMethod(this.newTask)
          this.newTask.user_id = ''
          this.newTask.type_of_task = ''
          this.newTask.deadline = ''
          this.newTask.counter_number = ''
          this.newTask.status = 'Opened'
          if (this.$route.name !== 'customer_page') {
            this.newTask.customer_id = ''
          }
          if (this.$route.name === 'customer_page') {
            this.searchCustomersField = ''
          }
          let result = await res.json()
          if (result.status === 'error') {
            this.showMessage(false, `[Error] Gescheitert ${typeMessage} eine Aufgabe`)
          } else {
            this.showMessage(true, `Die Aufgabe wurde erfolgreich abgeschlossen ${typeMessage}${event !== 'createTask' ? 'e' : ''}d`)
            this.clickFilter()
          }
          this.closePopup()
        } catch (e) {
          this.showMessage(false, `[Error] Gescheitert ${typeMessage} eine Aufgabe`)
          this.newTask = {
            user_id: '',
            customer_id: '',
            type_of_task: '',
            deadline: '',
            counter_number: '',
            status: 'Opened'
          }
          this.closePopup()
        }
      }
    },
    async searchCountersInTask(event) {
      if (this.newTask.customer_id !== '') {
        if (event.length) {
          let response = await this.$services.searchCountersInTask.callMethod({
            customer_id: this.newTask.customer_id,
            page: 1,
            search: event

          })
          this.countersInCustomerComputed = await response.json()
        } else {
          await this.getCountersInCustomer(this.newTask.customer_id)
        }
      }
    },
    async searchCustomersInPopup() {
      if (this.searchCustomersField.length) {
        let response = await this.$services.searchCustomersInPopup.callMethod({
          search: this.searchCustomersField,
          page: 1
        })
        this.customersForDropdown = await response.json()
      } else {
        this.customersForDropdown = []
      }
    },
    async createTypeOfTask() {
      try {
        await this.$services.createTypeOfTask.callMethod({type_of_task: this.type_of_task.value})
        this.type_of_tasks = []
        await this.getTypeOfTask()
      } catch (e) {
        console.error('[ERROR] ' + e.status + ' Failed to created type of task')
      }
      this.isPopupTypeOfTask = false
      this.type_of_task.value = ''
      this.type_of_task_id = ''
    },
    closePopupUserAndTypeTask() {
      this.isPopupTypeOfTask = false
      this.type_of_task.value = ''
      this.type_of_task_id = ''
    },
    async getCountersInCustomer(id) {
      let response = await this.$services.getCountersInCustomer.callMethod({customer_id: id, page: 1})
      this.countersInCustomer = await response.json()
    },
    editIdNumberCustomer(name, surname, id) {
      this.searchCustomersField = `${surname} ${name}`
      this.newTask.customer_id = id
      this.customersForDropdown = []
      this.getCountersInCustomer(id)
    },
    async getTypeOfTask() {
      try {
        let response = await this.$services.getTypeOfTask.callMethod({page: 1})
        const result = await response.json()
        this.type_of_tasks = []
        for (const typeOfTask in result) {
          this.type_of_tasks.push(result[typeOfTask].type_of_tasks)
        }
      } catch (e) {
      }
    },

  }
}
