export default {
    data() {
        return {
            deleteWindow: null
        }
    },
    methods: {
        async deleteTask(id) {
            try {
                this.deleteWindow = null
                await this.$services.deleteTasks.callMethod({id: [id]})
                await this.clickFilter(this.activeLinkFilter)
                this.showMessage(true, 'Aufgabe erfolgreich gelöscht')
            } catch (e) {
                this.showMessage(false, '[Error] Die Aufgabe konnte nicht gelöscht werden, versuchen Sie es erneut!')
            }
        },
    }
}