<template>
  <div>
    <div v-if="isPopupFolder" @click="$emit('closePopup')" class="mask"></div>
    <transition name="popup">
      <div v-if="isPopupFolder" class="popup">
        <button @click="$emit('closePopup')" class="popup__close-btn"></button>
        <h2>Ordner erstellen</h2>
        <div>
          <base-field-input label="NAME">
            <input v-model="new_folder.folder_name" type="text" placeholder="Name">
          </base-field-input>
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          <button @click.prevent="$emit('createFolder')" class="blue-button">
            <span></span>
            erstellen.
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import fieldErrorRequired from '@/components/fieldErrorRequired.vue'


export default {
  name: "createOperator",
  components: {BaseFieldInput, fieldErrorRequired},
  props: {
    isPopupFolder: Boolean,
    new_folder: {
      folder_name: String
    }
  }
}
</script>

<style lang="stylus">

</style>
