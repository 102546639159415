<template>
  <base_layout @searchInput="searchInput">
    <transition name="opacity">
      <message @resetMessage="resetMessage" v-if="isMessage" :success="successMessage" :message="addMessage"/>
    </transition>
    <div class="content">
      <div class="tasks">
        <div class="tasks__title-and-buttons">
          <h1 class="title-page">Aufgaben</h1>
          <div v-if="user.user_type[0].user_type === 'admin'" class="group-btn">
            <transition name="opacity">
              <div v-if="showEditForm" style="display: flex; align-items: center; margin-right: 16px;">
                <button @click.prevent="editGroupTasks" style="margin-right: 16px;" class="blue-button">
                  Speichern Sie
                </button>
                <v-select
                  v-model="editGroupTaskEmployee"
                  :options="userOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  placeholder="Mitarbeiter wechseln"
                  class="tasks__select-group"
                ></v-select>
                <v-select
                  v-model="editGroupTaskStatus"
                  :options="statusOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  placeholder="Status ändern"
                  class="tasks__select-group"
                  style="margin-left: 16px;"
                  :class="{'active-select': editGroupTaskStatus === 'Completed', 'closed-select': editGroupTaskStatus === 'Cancelled', 'opened-select': editGroupTaskStatus === 'Opened'}"
                ></v-select>
              </div>
            </transition>
            <button @click.prevent="showPopup('isPopupTask')" class="blue-button"><span></span>Neue Aufgabe
            </button>
          </div>
        </div>
        <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader">
          <span></span></div>
        <div style="height: 77vh;" :class="{'fadein-animation': !mainLoader}" v-else>
          <div class="tasks__sort-and-filters">
            <div class="tasks__sort">
              <a @click.prevent="clickFilter()" :class="{'tasks__sort-active': activeLinkFilter === null}"
                 href="#">Alle Aufgaben</a>
              <a @click.prevent="clickFilter('Opened')"
                 :class="{'tasks__sort-active': activeLinkFilter === 'Opened'}"
                 href="#">Geöffnet</a>
              <a @click.prevent="clickFilter('Completed')"
                 :class="{'tasks__sort-active': activeLinkFilter === 'Completed'}"
                 href="#">Abgeschlossen</a>
              <a @click.prevent="clickFilter('Cancelled')"
                 :class="{'tasks__sort-active': activeLinkFilter === 'Cancelled'}"
                 href="#">Abgesagt</a>
            </div>
          </div>
          <div ref="scrollContainer" @scroll="handleScroll('debounceSearchTasks', 'debounceGetTasks')"
               class="table">
            <table v-if="tasks.length !== 0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <td class="table__column-checkbox">
                  <base-checkbox id="all" @click="toggleAllCheckbox('tasks', 'showEditForm')"
                                 :val-checkbox="allCheckbox"/>
                </td>
                <td class="text-transform-uppercase">ID</td>
                <td class="text-transform-uppercase">Erstellt</td>
                <td class="text-transform-uppercase">Kunde/Schalter</td>
                <td class="text-transform-uppercase">Aufgabe</td>
                <td class="text-transform-uppercase" v-if="user.user_type[0].user_type === 'admin'">Mitarbeiter</td>
                <td class="text-transform-uppercase">Verbleibende Zeit</td>
                <td class="text-transform-uppercase">Status</td>
                <td class="text-transform-uppercase">Aktion</td>
              </tr>
              </thead>
              <tbody>
              <tr :class="{'tr-checked': task.task.isChecked}" :key="'task' + task.task.id"
                  v-for="(task, index) in tasks">
                <td class="table__column-checkbox">
                  <base-checkbox :id="'input' + task.task.id"
                                 @click="toggleRowCheckbox(task.task, 'showEditForm')"
                                 :val-checkbox="task.task.isChecked"/>
                </td>
                <td class="table__column-id">
                  <a href="#" @click.prevent="editOpenTask(task)">#{{ task.task.id }}</a>
                </td>
                <td>{{ task.task.create_of_date | date('date') }}</td>
                <td>{{ task.customer.length ? task.customer[0].name : '' }} {{
                    task.customer.length ? task.customer[0].surname : '-'
                  }}/{{ task.task.counter_number }}
                </td>
                <td>{{ task.task.type_of_task }}</td>
                <td v-if="user.user_type[0].user_type === 'admin'">
                  <div class="table__column-employee">
                    <span>{{ transformEmployee(task.user[0].first_name, task.user[0].last_name) }}</span>
                  </div>
                </td>
                <td class="table__column-deadline">
                  <div
                    :class="{'table__column-deadline-red': moment(task.task.deadline, 'DD.MM.YYYY').isBefore(moment())}">
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.7453 3.5094C4.01997 3.5094 1 6.52937 1 10.2547C1 13.98 4.01997 17 7.7453 17C11.4706 17 14.4906 13.98 14.4906 10.2547C14.4906 6.52937 11.4706 3.5094 7.7453 3.5094ZM7.7453 3.5094V1M7.7453 6.20752V10.2547L10.4434 11.6038"
                        stroke="#232323" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ task.task.deadline }}</span>
                  </div>
                </td>
                <td class="table__column-status"
                    :class="{
                      'table__column-status-open': task.task.status === 'Opened',
                      'table__column-status-cancel': task.task.status === 'Cancelled',
                      'table__column-status-complete': task.task.status === 'Completed'
                    }"
                >
                  <div>
                    <span></span>
                    {{ transformStatus(task.task.status) }}
                  </div>
                </td>
                <td style="position: relative">
                  <a @click.prevent="deleteWindow = index" style="position: relative; z-index: 1;" href="#">
                    <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                  </a>
                  <div v-if="deleteWindow === index" class="warning-delete">
                    Sind Sie sicher, dass Sie den Client entfernen möchten?
                    <div>
                      <button @click.prevent="deleteTask(task.task.id)">Ja</button>
                      <button @click.prevent="deleteWindow = null">Nein</button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
            <div v-if="isLoading" class="loader"><span></span></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="isPopupTask" @click="closePopup" class="mask"></div>
      <transition name="popup">
        <div v-if="isPopupTask" class="popup">
          <button @click="closePopup" class="popup__close-btn"></button>
          <h2 v-if="isPopupEditTask">Aufgabe bearbeiten</h2>
          <h2 v-else>Neue Aufgabe</h2>
          <div style="position: relative">
            <base-field-input label="Kunden suchen">
              <input v-model="searchCustomersField" @input="searchCustomersInPopup"
                     placeholder="Kunden suchen"
                     type="text"
                     :disabled="user.user_type[0].user_type === 'manager'"
                     :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
              >
            </base-field-input>
            <transition name="opacity">
              <div v-if="customersForDropdown.length" class="popup__search-dropdown">
                <a @click.prevent="editIdNumberCustomer(customer.name, customer.surname, customer.id)"
                   :key="'dropdown' + customer.id" v-for="customer in customersForDropdown"
                   href="#">{{ customer.surname }} {{ customer.name }}</a>
              </div>
            </transition>
          </div>
          <div>
            <base-field-input label="Suche nach Kundennummer">
              <input @change="getCountersInCustomer(newTask.customer_id)" disabled class="disabled-input"
                     placeholder="Suche nach Kundennummer" type="text"
                     v-model="newTask.customer_id">
              <div v-if="($v.newTask.customer_id.$dirty && !$v.newTask.customer_id.required)"
                   class="popup-customer__error">
                Dieses Feld ist obligatorisch
              </div>
            </base-field-input>
          </div>
          <div>
            <base-field-input label="Aufgabe"
                              :src_img="require(`@/assets/images/popup-type_icon.svg`)">
              <input
                style="padding-left: 64px;" type="text"
                v-model="newTask.type_of_task"
                placeholder="Aufgabe"
                :disabled="user.user_type[0].user_type === 'manager'"
                :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
              >
              <field-error-required
                v-if="($v.newTask.type_of_task.$dirty && !$v.newTask.type_of_task.required)"/>
            </base-field-input>
          </div>
          <div>
            <base-field-input label="Zählernummer"
                              :src_img="require(`@/assets/images/task-counter_icon.svg`)">
              <v-select
                placeholder="Zählernummer"
                class="vSelect"
                v-model="newTask.counter_number"
                :disabled="!countersInCustomerComputed.length || user.user_type[0].user_type === 'manager'"
                :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
              <field-error-required
                v-if="($v.newTask.counter_number.$dirty && !$v.newTask.counter_number.required)"
              />
            </base-field-input>
          </div>
          <div class="create-customer__block_50_50">
            <div>
              <base-field-input label="Ausführender Mitarbeiter"
                                :src_img="require('@/assets/images/popup-assign_icon.svg')">
                <v-select
                  placeholder="Ausführender Mitarbeiter"
                  class="vSelect"
                  v-model="newTask.user_id"
                  :options="userOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  :disabled="user.user_type[0].user_type === 'manager'"
                  :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
                ></v-select>
                <div v-if="($v.newTask.user_id.$dirty && !$v.newTask.user_id.required)"
                     class="popup-customer__error">
                  Dieses Feld ist obligatorisch
                </div>
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Deadline"
                                :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                <input
                  style="padding-left: 62px;"
                  type="text"
                  v-model="newTask.deadline"
                  @input="validation('newTask', 'deadline')"
                  placeholder="Date (DD.MM.YYYY)"
                  @keydown="filterInput"
                  :disabled="user.user_type[0].user_type === 'manager'"
                  :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
                >
                <div v-if="($v.newTask.deadline.$dirty && !$v.newTask.deadline.required)"
                     class="popup-customer__error">
                  Dieses Feld ist obligatorisch
                </div>
              </base-field-input>
            </div>
          </div>
          <div class="create-customer__block_50_50">
            <div></div>
            <div>
              <base-field-input label="">
                <v-select
                  class="vSelect"
                  v-model="newTask.status"
                  :options="statusOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  :class="{'active-select': newTask.status === 'Completed', 'closed-select': newTask.status === 'Cancelled', 'opened-select': newTask.status === 'Opened'}"
                ></v-select>
              </base-field-input>
            </div>
          </div>
          <div class="popup__group-btn">
            <a @click.prevent="closePopup" href="#">Abbrechen</a>
            <button v-if="isPopupEditTask" @click.prevent="editTask" class="blue-button">
              <span></span>
              Aufgabe bearbeiten
            </button>
            <button v-else @click.prevent="createTask" class="blue-button">
              <span></span>
              Neue Aufgabe
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div>
      <div v-if="isPopupTypeOfTask" @click="closePopupUserAndTypeTask" class="mask"></div>
      <transition v-if="isPopupTypeOfTask" name="popup">
        <div class="popup">
          <button @click="closePopupUserAndTypeTask" class="popup__close-btn"></button>
          <h2>Art der Aufgaben erstellen</h2>
          <div class="mt-24 w100">
            <base-field-input label="Art der Aufgabe"
                              :src_img="require('@/assets/images/popup-type_icon.svg')">
              <input class="show-icon" placeholder="Art der Aufgabe" type="text"
                     v-model="type_of_task.value">
            </base-field-input>
            <div v-if="($v.type_of_task.$dirty && !$v.type_of_task.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </div>
          <div class="popup__group-btn">
            <a @click.prevent="closePopupUserAndTypeTask" href="#">Abbrechen</a>
            <button @click.prevent="createTypeOfTask" class="blue-button">
              <span></span>
              <p>Art der Aufgabe erstellen</p>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

// PLUGINS
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import {debounce} from 'lodash'
import Vue from "vue";

// MIXINS
import togglePopup from "@/mixins/togglePopup";
import changeStatus from "@/mixins/tasks/changeStatus";
import transformEmployee from "@/mixins/transformEmployee";
import getUser from "@/mixins/getUser";
import success_error_state from "@/mixins/success_error_state";
import taskValidations from '@/mixins/tasks/validations'
import toggleCheckboxInTable from "@/mixins/toggleCheckboxInTable";
import lazyLoadScrollTable from "@/mixins/lazyLoadScrollTable";
import validations from "@/mixins/tasks/validations";
import create from "@/mixins/tasks/create";
import transformStatus from "@/mixins/transformStatus";

// COMPONENTS
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import Message from "@/components/message";
import fieldErrorRequired from '@/components/fieldErrorRequired'

//DECORATORS
import CustomSelect from "@/components/fields/CustomSelect.vue";
import eventDate from "@/mixins/eventDate";
import moment from 'moment'
import SelectField from "@/components/fields/SelectField.vue";

export default {
  name: "tasks_page",
  components: {
    SelectField,
    CustomSelect,
    Message,
    Base_layout,
    Datepicker,
    BaseFieldInput,
    BaseCheckbox,
    fieldErrorRequired,
    validations,
    vSelect
  },
  mixins: [transformStatus, eventDate, changeStatus, togglePopup, transformEmployee, success_error_state, taskValidations, getUser, toggleCheckboxInTable, lazyLoadScrollTable, create],
  data() {
    return {
      options: ['Opened', 'Cancelled', 'Completed'],
      user: null,
      tasks: [],
      users: null,
      activeLinkFilter: null,
      isLoading: false,
      countNextPage: 1,
      searchInputs: '',
      countSearchNextPage: 2,
      mainLoader: true,
      showEditForm: false,
      editGroupTaskEmployee: '',
      editGroupTaskStatus: '',
      moment: moment,
      deleteWindow: null
    }
  },
  async mounted() {
    let response = await this.$services.getUsers.callMethod()
    this.users = await response.json()
    await this.getTypeOfTask()
    await this.checkUserTypeAndStatusInTasks()
    this.mainLoader = false
  },
  methods: {
    async deleteTask(id) {
      try {
        this.deleteWindow = null
        await this.$services.deleteTasks.callMethod({id: [id]})
        await this.clickFilter(this.activeLinkFilter)
        this.showMessage(true, 'Aufgabe erfolgreich gelöscht')
      } catch (e) {
        this.showMessage(false, '[Error] Die Aufgabe konnte nicht gelöscht werden, versuchen Sie es erneut!')
      }
    },
    async searchTypeOfTask(event) {
      if (event.length !== 0) {
        this.type_of_tasks = []
        const response = await this.$services.searchTypeOfTask.callMethod({search: event})
        const result = await response.json()
        for (const typeOfTask in result) {
          this.type_of_tasks.push(result[typeOfTask].type_of_tasks)
        }
      } else {
        await this.getTypeOfTask()
      }
    },
    async searchPaginationInput() {
      let dataObj
      let response
      if (this.user.user_type[0].user_type === 'admin') {
        dataObj = {search: this.searchInputs, page: this.countSearchNextPage}
        response = await this.$services.searchTasks.callMethod(dataObj)
        if (response.status === 400) {
          this.isLoading = false
          this.checkHeight = true
        }
      } else {
        dataObj = {search: this.searchInputs, user_id: this.user.user.id, page: this.countSearchNextPage}
        response = await this.$services.searchTasks.callMethod(dataObj)
      }
      let result = await response.json()
      this.tasks = [...this.tasks, ...result]
      this.countSearchNextPage++

    },
    async searchInput() {
      const $event = event.target.value
      this.searchInputs = $event
      this.checkHeight = false
      this.isLoading = false
      this.search = true
      this.countSearchNextPage = 2
      let response
      let dataObj
      if (!$event.length) {
        this.clickFilter(this.activeLinkFilter)
      } else {
        if (this.user.user_type[0].user_type === 'admin') {
          dataObj = {search: $event, page: 1}
          response = await this.$services.searchTasks.callMethod(dataObj)
        } else {
          dataObj = {search: $event, user_id: this.user.user.id, page: 1}
          response = await this.$services.searchTasks.callMethod(dataObj)
        }
        this.tasks = await response.json()
      }
    },
    async clickFilter(filter = null) {
      this.search = false
      this.countNextPage = 2
      this.checkHeight = false
      this.isLoading = false
      let dataObj = {page: 1}
      this.activeLinkFilter = filter
      if (filter !== null) {
        dataObj.status = filter
      }
      if (this.user.user_type[0].user_type === 'admin') {
        this.tasks = await this.getTasks(dataObj)
      } else {
        dataObj.user_id = this.user.user.id
        this.tasks = await this.getTasks(dataObj)
      }
      this.addIsCheckedBool()
    },
    async checkUserTypeAndStatusInTasks() {
      let result
      let dataObj = {
        page: this.countNextPage
      }
      if (this.activeLinkFilter !== null) {
        dataObj.status = this.activeLinkFilter
      }

      if (this.user.user_type[0].user_type === 'admin') {
        result = await this.getTasks(dataObj)

        if (result !== undefined) {
          this.tasks = [...this.tasks, ...result]
        }
      } else {
        dataObj.user_id = this.user.user.id
        result = await this.getTasks(dataObj)

        if (result !== undefined) {
          this.tasks = [...this.tasks, ...result]
        }
      }
      this.countNextPage++

      this.addIsCheckedBool()
      this.isLoading = false
    },
    addIsCheckedBool() {
      this.tasks.forEach((task) => {
        Vue.set(task.task, 'isChecked', false)
      })
    },
    async getTasks(obj) {
      try {
        let response = await this.$services.getTasks.callMethod(obj)
        return await response.json()
      } catch (e) {
        this.isLoading = false
        this.checkHeight = true
        console.warn('[WARN]: The tasks are over!')
      }
    },
    async editGroupTasks() {
      let array = []
      for (let task in this.tasks) {
        if (this.tasks[task].task.isChecked)
          array.push(this.tasks[task].task.id)
      }
      let obj = {task_ids: array}
      if (this.editGroupTaskEmployee !== '') {
        obj.user_id = this.editGroupTaskEmployee
      }
      if (this.editGroupTaskStatus !== '') {
        obj.status = this.editGroupTaskStatus
      }
      try {
        await this.$services.editTasks.callMethod(obj)
        this.editGroupTaskEmployee = ''
        this.editGroupTaskStatus = ''
        this.showEditForm = false
        await this.clickFilter(this.activeLinkFilter)
      } catch (e) {
        console.error('[ERROR] Failed to delete tasks' + e.status)
      }
    }
  },
  created() {
    this.debounceGetTasks = debounce(this.checkUserTypeAndStatusInTasks, 200)
    this.debounceSearchTasks = debounce(this.searchPaginationInput, 200)
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/popup.styl"

.tasks
  height: 100%

  &__select-group
    width: 200px
    height: 45px
    & .vs
      &__dropdown-toggle
        height: 100%
        border 1px solid #517bfb

      &__clear > svg,
      &__open-indicator
        fill #517bfb

      &__search
        border none !important

        &::placeholder
          color #517bfb !important

      &__dropdown-menu
        border-color #517bfb

    &.vs--open
      & .vs__selected
        position initial

  &__title-and-buttons
    display flex
    justify-content space-between
    align-items center

  &__change-select
    margin-right: 16px
    border 2px solid #517BFB
    padding: 11.5px 24px
    border-radius 4px
    color #517BFB
    cursor pointer
    outline none

  &__sort-and-filters
    margin-top: 50px
    margin-bottom: 40px
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #B6BED8
    padding-bottom: 8px

  &__sort
    &-active
      color $blue !important
      border-bottom 4px solid $blue
      text-shadow 2px 2px 4px rgba(31, 85, 250, 0.3)

    a
      color #999999
      font-size: 20px
      font-weight: 500
      margin-left: 32px
      padding-bottom: 4px

      &:first-child
        margin-left: 0

</style>
