<template>
    <div :class="{'show-image': src_img}" class="field-input">
        <label v-if="label">{{ label.toUpperCase() }}</label>
        <slot></slot>
        <img :src="src_img" alt="">
    </div>
</template>

<script>
export default {
    name: "BaseFieldInput",
    props: {
        label: String,
        src_img: String,
    },
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.show-image:after
    content ''
    display block
    height: calc(100% - 22px)
    width: 0.5px
    background #B6BED8
    position absolute
    left: 48px
    top: 22px

.show-image
    .vSelect .vs__selected-options
        padding-left: 58px

.field-input
    position relative
    width: 100%
    margin-bottom: 18px

    .pending-select
        background rgba(#fdab3d, 0.2)
        border 1px solid #fdab3d
        color #fdab3d

    .popup-customer__error + img
        top: 50%

    .show-icon
        padding-left: 60px

    .show-icon-datepicker
        input
            padding-left: 60px

    & > label + div
        button
            display none

    .mx-icon-calendar
        display none !important

    .mx-datepicker
        width: 100%

    input
        height: 44px
        border 1px solid
        border-color #B6BED8
        padding-left: 16px
        font-size: 16px
        border-radius 4px
        background none

    textarea
        padding: 10px 16px
        line-height 1.5
        font-size: 16px
        border-color #B6BED8
        min-width: 100%
        max-width: 100%;
        min-height 100px
        height: 100px
        max-height 200px
        border-radius 4px
        transition border-color 0.2s
        outline none
        &:hover, &:focus
            border-color #517bfb !important

    img
        position absolute
        left: 15px
        top: 64%
        transform translateY(-50%)
        z-index 1

    select
        width: 100%
        height: 44px
        padding-left: 16px
        color #5A5A5A
        font-size: 16px
        border-radius 4px
        border 1px solid
        border-color #B6BED8
        outline none
        cursor: pointer
        background none
        transition 0.2s

        &:focus
        &:hover
            border 1px solid $blue

    label
        margin-bottom: 8px
        display block
        color #B6BED8
        font-size: 14px
        font-weight: 600


</style>
