import {debounce} from "lodash";

export default {
    data() {
        return {
            deleteWindowOperator: null,
            operator: {
                operator_name: '',
                operator_street: '',
                operator_house_number: '',
                operator_postcode: '',
                operator_city: '',
                operator_email: '',
                operator_email_private: '',
                operator_phone: '',
                operator_fax: ''
            },
            mainOperatorLoader: true,
            operators: [],
            countNextPageOperators: 1,
            isLoadingOperators: false,
            checkHeightOperators: false,
            searchOperators: false
        }
    },
    methods: {
        async getPaginationOperators() {
            try {
                const response = await this.$services.getOperators.callMethod({page: this.countNextPageOperators})
                const result = await response.json()
                this.operators = [...this.operators, ...result]
                this.countNextPageOperators++
            } catch (e) {
                this.isLoadingOperators = false
                this.checkHeightOperators = true
            }
        },
        async createOperator() {
            await this.initEventOperator('createOperator', 'create')
        },
        async editOperator() {
            await this.initEventOperator('editOperator', 'edit')
        },
        async initEventOperator(event, field) {
            try {
                let response = await this.$services[event].callMethod(this.operator)
                this.operators = await this.getOperators()
                if (await response.json().status === 'error') {
                    this.showMessage(false, `[Error] ${field} ein Operator fehlgeschlagen`)
                } else {
                    this.showMessage(true, `Der Betreiber wurde erfolgreich ${field}${field === 'create' ? 'd' : 'ed'}`)
                }
            } catch (e) {
                this.showMessage(false, `[Error] ${field} ein Operator fehlgeschlagen`)
            } finally {
                this.closePopup()
            }
        },
        async getOperators() {
            let response = await this.$services.getOperators.callMethod({page: 1})
            return await response.json()
        },
        async deleteOperator(id) {
            try {
                this.deleteWindowOperator = null
                let response = await this.$services.deleteOperator.callMethod({operator_id: id})
                if (await response.json().status === 'error') {
                    this.showMessage(false, `[Error] Das Löschen eines Operators ist fehlgeschlagen`)
                } else {
                    this.showMessage(true, `Der Betreiber wurde erfolgreich gelöscht`)
                    this.operators = await this.getOperators()
                }
            } catch (e) {
                this.showMessage(false, `[Error] Das Löschen eines Operators ist fehlgeschlagen`)
            }
        }
    },
    async mounted() {
        this.debounceGetOperators = debounce(this.getPaginationOperators, 200)
        await this.getPaginationOperators()
        this.mainOperatorLoader = false
    }
}
