<template>
  <section class="birthday">
    <div v-if="isShowPopup" @click="togglePopup" class="mask"></div>
    <transition name="popup">
      <div v-if="isShowPopup" class="popup-bot__inner popup">
        <button @click="togglePopup" class="popup__close-btn"></button>
        <h2>Geburtstag Vorlage</h2>
        <div>
          <div>
            <base-field-input label="Vorlage">
              <textarea v-model="template.congratulation" placeholder="Vorlage"></textarea>
            </base-field-input>
          </div>
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="togglePopup" href="#">Abbrechen</a>
          <button @click.prevent="editTemplate" class="blue-button">
            Vorlage ändern
          </button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";

export default {
  name: "Birthday",
  components: {BaseFieldInput},
  data() {
    return {
      isShowPopup: false,
      template: {
        id: null,
        congratulation: ''
      },
    }
  },
  methods: {
    togglePopup() {
      this.isShowPopup = !this.isShowPopup
    },
    async editTemplate() {
      try {
        const response = await this.$services.editCongratulationTemplate.callMethod(this.template)
        const result = await response.json()
        if (result.status === 'error') {
          this.$emit('errorMessage')
        } else {
          this.$emit('successMessage')
        }
      } catch (e) {
        console.error('[ERROR]' + e)
        this.$emit('errorMessage')
      } finally {
        this.togglePopup()
      }
    },
    async getTemplate() {
      try {
        const response = await this.$services.getCongratulationTemplate.callMethod()
        this.template = await response.json()
      } catch (e) {
        console.error('[ERROR]' + e)
      }
    }
  },
  async mounted() {
    await this.getTemplate()
  }
}
</script>

<style lang="stylus">
.birthday
  & textarea
    min-height 200px
    height: 200px
    max-height 400px

</style>