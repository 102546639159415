export default function dateFilter(value, format = 'date') {
    let options = {}
    if (value !== '' && value !== null) {
        if (format.includes('date')) {
            options.year = 'numeric'
            options.month = '2-digit'
            options.day = '2-digit'

            value = new Intl.DateTimeFormat('ru-RU', options).format(new Date(value))
        }
        if (format.includes('day')) {
            const date = new Date(value)
            const today = new Date()
            const timeDiff = date.getTime() - today.getTime()
            value = (Math.floor(timeDiff / (1000 * 3600 * 24)) + 1)
            if (value === 1 || value === 0 || value === -1) {
                value += ' day';
            } else {
                value += ' days'
            }

        }
    } else {
        value = ''
    }
    return value
}
