<template>
    <base-layout>
        <div style="position: relative; min-height: 100vh">
            <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader"><span></span>
            </div>
            <div v-else :class="{'fadein-animation': !mainLoader}" class="content dashboard">
                <div style="width: 100%;" class="tasks__title-and-buttons">
                    <h1 class="title-page">Statistics</h1>
                </div>
                <div class="dashboard__period-date">
                    <div class="dashboard__input">
                        <base-field-input label="ANFANGSDATUM">
                            <input
                                type="number"
                                placeholder="YYYY"
                                v-model="period.start_period_date"
                                @click="eventInput('start_period_date')"
                            >
                        </base-field-input>
                    </div>
                    <span>-</span>
                    <div class="dashboard__input">
                        <base-field-input label="ENDE-DATUM">
                            <input
                                type="number"
                                placeholder="YYYY"
                                v-model="period.end_period_date"
                                @click="eventInput('end_period_date')"
                            >
                        </base-field-input>
                    </div>
                    <button @click.prevent="getStatistics" class="blue-button">Statistiken abrufen</button>
                </div>
                <div v-if="showPeriod" class="dashboard__get-period">
                    Statistics for the period 31.12.{{ period.start_period_date }} -
                    01.01.{{ period.end_period_date }}
                </div>
                <div v-else class="dashboard__get-period">
                    Statistiken für den gesamten Zeitraum
                </div>
                <div class="dashboard__statistics-content">
                    <div class="white-container dashboard__statistics">
                        <div class="dashboard__statistics-type-and-icon">
                            <div class="dashboard__statistics-type">
                                Privatkunden
                                <span>{{ statistics.privat.privat_qty_customer }}</span>
                            </div>
                            <div class="dashboard__statistics-icon">
                                <img src="@/assets/images/dashboard-private_icon.svg" alt="">
                            </div>
                        </div>
                        <div class="dashboard__statistics-items">
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.privat.qty_electricity }}
                                        <span>Stromzähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.privat.amount_consumption_electricity }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.privat.qty_gas }}
                                        <span>Gaszähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.privat.amount_consumption_gas }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="white-container dashboard__statistics">
                        <div class="dashboard__statistics-type-and-icon">
                            <div style="color: #517BFB" class="dashboard__statistics-type">
                                Geschäftskunden
                                <span>{{ statistics.company.company_qty_customer }}</span>
                            </div>
                            <div style="background: rgba(81, 123, 251, 0.2)" class="dashboard__statistics-icon">
                                <img src="@/assets/images/dashboard-corporate_icon.svg" alt="">
                            </div>
                        </div>
                        <div class="dashboard__statistics-items">
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.company.qty_electricity }}
                                        <span>Stromzähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.company.amount_consumption_electricity }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.company.qty_gas }}
                                        <span>Gaszähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.company.amount_consumption_gas }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="white-container dashboard__statistics">
                        <div class="dashboard__statistics-type-and-icon">
                            <div style="color: #cc3333" class="dashboard__statistics-type">
                                RLM
                                <span>{{ statistics.rlm.rlm_qty_customer }}</span>
                            </div>
                            <div style="background: rgba(204, 51, 51, 0.2)" class="dashboard__statistics-icon">
                                <img src="@/assets/images/dashboard-rlm.svg" alt="">
                            </div>
                        </div>
                        <div class="dashboard__statistics-items">
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.rlm.qty_electricity }}
                                        <span>Stromzähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-energy-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.rlm.amount_consumption_electricity }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__statistics-row">
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.rlm.qty_gas }}
                                        <span>Gaszähler</span>
                                    </div>
                                </div>
                                <div class="dashboard__statistics-item">
                                    <div style="background: rgba(59, 102, 254, 0.2)"
                                         class="dashboard__statistics-item-icon">
                                        <img src="@/assets/images/dashboard-gas-consumption_icon.svg" alt="">
                                    </div>
                                    <div class="dashboard__statistics-item-data">
                                        {{ statistics.rlm.amount_consumption_gas }}
                                        <span>Verbrauch in kWh</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base_layout";
import togglePopup from "@/mixins/togglePopup";
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";

export default {
    name: "dashboard_page",
    components: {baseLayout, Datepicker, BaseFieldInput},
    mixins: [togglePopup],
    data() {
        return {
            user: null,
            statistics: {},
            period: {
                start_period_date: '',
                end_period_date: ''
            },
            showPeriod: false,
            mainLoader: true
        }
    },
    methods: {
        async eventInput(field) {
            this.showPeriod = false
            this.period[field] = ''
            await this.getStatistics()
        },
        async getStatistics() {
            let response
            try {
                if (this.period.start_period_date !== '' && this.period.end_period_date !== '') {
                    response = await this.$services.getStatistics.callMethod(this.period)
                    this.showPeriod = true
                } else {
                    response = await this.$services.getStatistics.callMethod()
                }
                this.statistics = await response.json()
            } catch (e) {
            }
        }
    },
    async mounted() {
        await this.getStatistics()
        this.mainLoader = false
    },
    created() {
    }
}
</script>

<style lang="stylus">

.dashboard
    display flex
    flex-direction column
    align-items flex-start

    &__input
        max-width 200px

    &__get-period
        font-size: 20px
        font-weight: 500

    &__period-date
        display flex
        align-items center
        margin-top: 32px

        span
            display block
            margin: 0 16px

        button
            margin-left: 32px

    &__table
        margin-top: 64px
        border-radius 10px
        max-height calc(100vh - 496px)

        .table
            margin-top: 24px
            max-height: calc(100% - 80px)

    &__statistics
        padding: 36px 28px
        max-width 48%
        width: 100%

        &:first-child
            margin-right: 24px

        &:last-child
            margin-top: 24px

        &-content
            display flex
            flex-wrap wrap
            justify-content flex-start
            margin-top: 32px

        &-row
            display flex
            justify-content space-between
            align-items center

            &:first-child
                margin-bottom: 40px

        &-items
            margin-top: 50px

        &-item
            display flex
            align-items center

            &-icon
                min-width: 40px
                min-height: 40px
                background rgba(#CC3333, 0.2)
                border-radius 100%
                margin-right: 20px
                display flex
                align-items center
                justify-content center

            &-data
                font-size: 18px
                font-weight: 700

                span
                    display block
                    margin-top: 2px
                    color #999999
                    font-size: 16px
                    font-weight: 400

        &-type-and-icon
            display flex
            align-items center
            justify-content space-between

        &-type
            color #00C875
            font-weight: 700

            span
                margin-top: 18px
                display block
                font-size: 32px

        &-icon
            width: 60px
            height: 60px
            border-radius 100%
            background rgba(#00C875, 0.2)
            display flex
            align-items center
            justify-content center

</style>
