<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Energieverwaltung';
      },
    },
  },
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

@font-face
    font-family: 'Roboto'
    src: local('Roboto'), local('Open-Sans Light'), url("assets/fonts/roboto/Roboto-Light.woff2") format('woff2'), url("assets/fonts/roboto/Roboto-Light.woff") format('woff'), url("assets/fonts/roboto/Roboto-Light.ttf") format('truetype')
    font-weight: 300
    font-style normal

@font-face
    font-family: 'Roboto'
    src: local('Roboto'), local('Open-Sans Regular'), url("assets/fonts/roboto/Roboto-Regular.woff2") format('woff2'), url("assets/fonts/roboto/Roboto-Regular.woff") format('woff'), url("assets/fonts/roboto/Roboto-Regular.ttf") format('truetype')
    font-weight: 400
    font-style normal

@font-face
    font-family: 'Roboto'
    src: local('Roboto'), local('Open-Sans Medium'), url("assets/fonts/roboto/Roboto-Medium.woff2") format('woff2'), url("assets/fonts/roboto/Roboto-Medium.woff") format('woff'), url("assets/fonts/roboto/Roboto-Medium.ttf") format('truetype')
    font-weight: 500
    font-style normal

@font-face
    font-family: 'Roboto'
    src: local('Roboto'), local('Open-Sans Bold'), url("assets/fonts/roboto/Roboto-Bold.woff2") format('woff2'), url("assets/fonts/roboto/Roboto-Bold.woff") format('woff'), url("assets/fonts/roboto/Roboto-Bold.ttf") format('truetype')
    font-weight: 700
    font-style normal

*,
*:after,
*:before
    box-sizing border-box
    margin: 0
    padding: 0
    font-family: 'Roboto', sans-serif
    line-height 1

body
    font-size: 16px
    color $black

a
    text-decoration none
    color $blue

button
    cursor pointer

ul
    list-style-type none

input
    width: 100%
    transition 0.2s
    outline none

    &:focus
    &:hover
        border-color $blue !important

.text-transform-uppercase
    text-transform uppercase
    font-size: 14px !important
    padding: 8px !important

.active-select
  color #00C875 !important
  & .vs
    &__dropdown-toggle
      background #c3f1de !important
      border-color #00C875 !important
    &__dropdown-menu
      background #c3f1de !important
      border 1px solid #00C875 !important
    &__clear > svg,
    &__open-indicator
      fill #00C875 !important
    &__selected
      color #00C875 !important

.closed-select
  color #c33 !important
  & .vs
    &__dropdown-toggle
      background #ffd8d8 !important
      border 1px solid #c33 !important
    &__dropdown-menu
      background #ffd8d8 !important
      border-color #c33 !important
    &__clear > svg,
    &__open-indicator
      fill #c33 !important
    &__selected
      color #c33 !important

.opened-select
  color #fdab3d !important
  & .vs
    &__dropdown-toggle
      background #fae2d0 !important
      border-color #fdab3d !important
    &__dropdown-menu
      background #fae2d0 !important
      border 1px solid #fdab3d !important
    &__clear > svg,
    &__open-indicator
      fill #fdab3d !important
    &__selected
      color #fdab3d !important

.vSelect
  height: 44px
  & div
    width auto !important
  & input
    height auto !important
    border none !important
    padding-left: 0 !important
    &::placeholder
      color #5A5A5A
  &.vs--disabled
    input,
    .vs__dropdown-toggle
      background-color #ecebeb !important
      &:hover
        border-color #5a5a5a !important
    .vs__open-indicator
      background none
  & .vs
    &__dropdown-menu
      border-color #b6bed8 !important
    &__dropdown-toggle
      height: 100%
      border-color #b6bed8
      transition 0.2s
      background none
      &:hover
        border-color #517bfb !important
    &__selected-options
      padding-left: 8px
    &__selected
      line-height 1
      position initial !important
    &__dropdown-option--highlight
      background #999999 !important

.container
    max-width 1920px
    width: 100%
    margin: 0 auto

.content
    position relative
    background #F7F8FD
    width: 100%
    height calc(100vh - 104px)
    padding: 36px 64px
    overflow-y scroll


.input
    height: 54px
    border 1px solid
    border-color #5A5A5A
    padding-left: 72px
    font-size: 20px
    border-radius 4px
    background none

.label
    color #5A5A5A
    margin-bottom: 8px
    display block

.title-page
    font-size: 32px
    font-weight: 700

.delete-button
    border 2px solid
    border-color #CC3333
    background none
    color #CC3333
    display flex
    align-items center
    padding: 14px 24px
    border-radius 4px
    font-weight: 500

    span
        margin-right: 10px
        position relative
        padding: 6px
        display inline-block

        &:after
        &:before
            content ''
            display block
            width: 2px
            height: 12px
            border-radius 8px
            background #CC3333
            position absolute
            top: 0
            left 5px

        &:after
            transform rotate(45deg)

        &:before
            transform rotate(-45deg)

.border-button
    border 1px solid $blue
    background white
    color $blue
    font-weight: 500
    padding: 15px 24px
    outline none
    display flex
    align-items center
    transition 0.2s
    border-radius 3px
    &:hover
        opacity 0.8

.warning-delete
  background white
  border 1px solid #B6BED8
  padding: 12px 16px
  border-radius 4px
  position absolute
  right: 0
  top: 0
  bottom: 0
  width: 508px
  z-index 10
  text-align center
  display flex
  align-items center
  div
    margin-left: 8px
    button
      border none
      background none
      font-size: 16px
      color green
      transition 0.2s
      padding: 4px
      border-radius 3px
      &:hover
        background #f9f9f9
    button + button
      color red
      margin-left: 8px

.blue-button
    background $blue
    font-weight: 500
    color white
    padding: 15px 24px
    outline none
    border 1px solid
    border-color $blue
    border-radius 3px
    display flex
    align-items center
    transition 0.2s
    &:hover
        opacity 0.8

    img
        margin-right: 12px

    span
        width: 12px
        height: 12px
        position relative
        display block
        margin-right: 12px

        &:before
        &:after
            content ''
            border-radius 6px
            width: 2px
            height: 12px
            position absolute
            background white

        &:after
            transform rotate(90deg) translateY(-50%)
            left: -1px
            top: 0

        &:before
            left: 50%
            transform translateX(-50%)
            top: 0

.grey-button
    border 1px solid
    border-color #E6E6E6
    color $blue
    padding: 16px 24px
    border-radius 3px
    font-weight: 500
    background #F0F0F0
    display flex
    align-items center

    span
        width: 4px
        height: 4px
        display flex
        border-radius 100%
        background $blue
        margin-left: 8px

        &:after
        &:before
            content ''
            display block
            background $blue
            min-width: 4px
            height: 4px
            border-radius 100%

        &:before
            margin-left: 6px
            margin-right: 2px

.arrow
    border-color: #5A5A5A transparent transparent transparent;
    border-style: solid;
    border-width: 6px 5px 0 5px;

.filters
    cursor: pointer
    display flex
    align-items center

    img
        margin-right: 12px

    span
        margin-left: 16px
        display block
        font-size: 20px

.checkbox
    input[type='checkbox']:checked
    input[type='checkbox']:not(:checked)
        position absolute
        left: -99999px

    input[type='checkbox']:checked + label
    input[type='checkbox']:not(:checked) + label
        position: relative
        cursor: pointer
        height: 16px
        width: 16px
        display flex
        align-items center
        justify-content center
        transition 0.1s
        border-radius 100%
        background none

    input[type='checkbox']:checked + label
        border 1px solid $blue

    input[type='checkbox']:not(:checked) + label
        border 1px solid #999999

    input[type="checkbox"]:checked + label:after
    input[type="checkbox"]:not(:checked) + label:after
        content ''
        width: 7px
        height: 7px
        display block
        position absolute
        background $blue
        border-radius 100%
        transition 0.1s

    input[type="checkbox"]:checked + label:after
        opacity 1

    input[type="checkbox"]:not(:checked) + label:after
        opacity 0

.mask
    cursor: pointer
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    background rgba(3, 40, 150, 0.2)
    backdrop-filter: blur(1px)
    z-index 12

.group-btn
    display flex
    align-items center

    button + button
        margin-left: 16px

.tr-checked
    &:after
        opacity 1 !important

    &:before
        background #F7F7F7 !important


.table
    width: 100%
    max-height calc(100% - 100px)
    overflow-y auto

    &::-webkit-scrollbar
        display none

    table
        width: 100%

    td
        padding: 12px
        position relative
        z-index 2
        max-height 70px

        &:first-child
            padding-left: 24px

    tr
        position relative

        &:after
            content ''
            height: 100%
            width: 6px
            background $blue
            display block
            position absolute
            left: 0
            top: 0
            z-index 2
            opacity 0
            transition 0.2s

        &:before
            content ''
            border 1px solid #E6E6E6
            position absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            background white
            z-index 1

    thead
        tr:before
            opacity 0

        td
            font-weight: 500

    &__column
        &-folder-name
            a
                display flex
                align-items center

                img
                    margin-right: 16px

        &-address
            span
                font-weight: 400
                max-width: 15vw;
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
                display: inline-block
                vertical-align middle

        &-employee
            width: 35px
            height: 35px
            font-weight: 700
            color #5A5A5A
            background #F0F0F0
            border-radius 100%
            display flex
            align-items center
            justify-content center
            font-size: 15px
            overflow hidden

            img
                width: 35px
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: center center;
                object-position: center center;

        &-status
            select
                border none
                outline none
                font-size: 16px
                cursor: pointer

            &-open
                div
                    color #FDAB3D !important

                    span
                        background rgba(#FDAB3D, 0.2) !important

                        &:after
                            background #FDAB3D !important

            &-complete
                div
                    color #00C875 !important

                    span
                        background rgba(#00C875, 0.2) !important

                        &:after
                            background #00C875 !important

            &-cancel
                div
                    color #CC3333 !important

                    span
                        background rgba(#CC3333, 0.2) !important

                        &:after
                            background #CC3333 !important

            div
                display flex
                align-items center
                color #c9c9c9

                span
                    background rgba(#c9c9c9, 0.2)
                    border-radius 100%
                    width: 15px
                    height: 15px
                    position relative
                    display flex
                    align-items center
                    justify-content center
                    margin-right: 12px

                    &:after
                        content ''
                        display block
                        background #c9c9c9
                        width: 8px
                        height: 8px
                        border-radius 100%


        &-deadline
            &-red
                color #CC3333

                svg
                    path
                        stroke #CC3333

            div
                display flex
                align-items center

                svg
                    margin-right: 8px
                    margin-top: -2px

        &-checkbox
            width: 60px

        &-action
            width: 80px
            position relative

            &-link
                cursor: pointer
                background #F0F0F0
                width: 32px
                height: 35px
                border-radius 10px
                display flex
                flex-direction column
                align-items center
                justify-content center

                span
                    width: 4px
                    height: 4px
                    background $black
                    border-radius 100%
                    display block
                    margin-top: -19px

                    &:after
                    &:before
                        content ''
                        display block
                        min-width: 4px
                        height: 4px
                        background $black
                        border-radius 100%

                    &:after
                        margin-top: 5px

                    &:before
                        margin-top: 9px

            &-dropdown
                width: 200px
                border 2px solid #517BFB
                position absolute
                top: 55px
                right: 0
                background white

                a
                    display block
                    padding: 12px 24px
                    color $black

        &-id
            a
                color $blue !important

.white-container
    background white
    border 1px solid #E6E6E6
    border-radius 12px
    padding: 36px 42px

.logo
    cursor pointer
    display flex
    align-items center
    text-transform uppercase
    font-size: 24px
    font-weight: 500
    color $blue
    min-width 273px
    transition 0.2s
    margin-left: 16px

    img
        margin-right: 20px

.loader
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    width: 100px
    margin: 0 auto

    &::before
        content: ''
        display: block
        width: 50px
        height: 50px
        border-radius: 50%
        border: 3px solid
        border-color: $blue transparent $blue transparent
        animation: loader-rotate 2s linear infinite

@keyframes loader-rotate
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.big-loader
    position absolute
    top: 50%
    left: 50%
    transform translate(-50%, -50%)
    z-index 100

    &::before
        width: 100px !important
        height: 100px !important
        border: 8px solid
        border-color: $blue transparent $blue transparent

.opacity
  &-enter-active
  &-leave-active
    opacity 1
    transition opacity 0.2s

  &-enter
  &-leave-to
    opacity 0


.warn-blank-table
    text-align center
    font-size: 24px
    font-weight: 500
    color #999

.mt-24
    margin-top: 24px

.ml-16
    margin-left: 16px

.w100
    width: 100%

.disabled-input
    background #ecebeb !important
    cursor not-allowed !important

    &:hover
        border 1px solid #5a5a5a !important

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadein-animation {
    animation: fadeIn 0.5s;
}

.fadeout-animation {
    animation: fadeOut 0.5s
}

@media screen and (max-width: $break-point-1700)
    .content
        padding: 24px
        border-radius 10px

    .white-container
        padding: 36px 20px

</style>
