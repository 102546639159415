<template>
  <auth-layout>
    <h1 class="title-page">Autorisierung</h1>
    <div v-if="showMessage" class="auth__error fadein-animation">
      Falsches Login oder Passwort
    </div>
    <div class="auth__form">
      <div class="auth__input">
        <label class="label">Login</label>
        <input v-model="username" placeholder="Anmeldung" class="input" type="text">
      </div>
      <div class="auth__input">
        <label class="label">Password</label>
        <input v-model="password" placeholder="Passwort" class="input" type="password">
      </div>
      <div class="auth__button">
        <button @click.prevent="auth" class="blue-button">Eintragen</button>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/auth_layout'

export default {
  name: "auth_page",
  components: {AuthLayout},
  data() {
    return {
      username: '',
      password: '',
      showMessage: false
    }
  },
  methods: {
    async auth() {
      let response = await this.$services.authorizeUser.callMethod({
        username: this.username,
        password: this.password
      })
      const data = await response.json()
      if (data.message === 'error') {
        this.showMessage = true
        setTimeout(() => {
          this.showMessage = false
        }, 5000)
      } else {
        localStorage.setItem('auth_token', data.token)
        this.$router.push({name: 'tasks_page'})
      }
    }
  },
}
</script>

<style lang="stylus">

.auth
  &__form
    margin-top: 32px

    input
      padding-left: 24px

  &__error
    margin-top: 16px
    padding: 9px 18px
    background rgba(#cc3300, 0.2)
    border 1px solid #cc3300
    color #cc3300
    border-radius 4px

  &__input
    margin-top: 24px

    &:first-child
      margin-top: 0

  &__button
    display flex
    justify-content flex-end
    margin-top: 32px

    button
      text-transform uppercase
      font-size: 18px
      padding: 12px 24px

</style>
