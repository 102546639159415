export default {
    data() {
        return {
            deleteWindowUser: null,
            create_user: {
                username: '',
                password: '',
                last_name: '',
                first_name: '',
                image: ''
            },
            isPopupUser: false,
            isPopupEditUser: false,
            mainUserLoader: true,
        }
    },
    methods: {
        async createUser() {
            try {
                let result = await this.initEventUser('createUser')
                if (result.status === 'error') {
                    this.showMessage(false, '[Error] Fehler beim Erstellen eines Benutzers')
                } else {
                    this.showMessage(true, 'Der Benutzer wurde erfolgreich erstellt')
                }
            } catch (e) {
                this.showMessage(false, '[Error] Fehler beim Erstellen eines Benutzers')
            }
        },
        async editUser() {
            try {
                let result = await this.initEventUser('editUser')
                if (result.status === 'error' && !this.$v.create_user.$invalid) {
                    this.showMessage(false, '[Error] Bearbeitung eines Benutzers fehlgeschlagen')
                } else if (!this.$v.create_user.$invalid) {
                    this.showMessage(true, 'Der Benutzer wurde erfolgreich bearbeitet')
                }
            } catch (e) {
                this.showMessage(false, '[Error] Bearbeitung eines Benutzers fehlgeschlagen')
            }
        },
        async initEventUser(service) {
            if (this.$v.create_user.$invalid) {
                this.$refs.createUser.callValidation()
                return
            } else {
                try {
                    const formData = new FormData()
                    const file = this.$refs.createUser.getInputImage().files[0]
                    formData.append('user', JSON.stringify(this.create_user))
                    formData.append('file', file)
                    let response = await this.$services[service].callMethod(formData)
                    await this.getUsers()
                    this.closePopup()
                    this.create_user = {
                        username: '',
                        password: '',
                        last_name: '',
                        first_name: '',
                    }
                    return await response.json()
                } catch (e) {
                    console.error('[ERROR] ' + e.status + ' Failed to created user')
                    this.closePopup()
                    this.create_user = {
                        username: '',
                        password: '',
                        last_name: '',
                        first_name: '',
                    }
                }
            }
        },
        async editOpenUserPopup(user) {
            this.isPopupEditUser = true
            this.isPopupUser = true
            this.create_user.username = user.username
            this.create_user.first_name = user.first_name
            this.create_user.last_name = user.last_name
            this.create_user.user_id = user.id
        },
        openSystemFile() {
            this.$refs.createUser.getInputImage().click()
        },
        handleFileChange() {
            const selectedFile = this.$refs.createUser.getInputImage().files[0];
            this.nameImage = selectedFile.name
        },
        async getUsers() {
            try {
                let response = await this.$services.getUsers.callMethod()
                this.users = await response.json()
            } catch (e) {
            }
        },
        async deleteUser(id) {
            try {
                this.deleteWindowUser = null
                let response = await this.$services.deleteUser.callMethod({user_id: id})
                if (await response.json().status === 'error') {
                    this.showMessage(false, '[Error] Löschen eines Benutzers fehlgeschlagen')
                } else {
                    this.showMessage(true, 'Der Benutzer wurde erfolgreich gelöscht')
                }
                await this.getUsers()
            } catch (e) {
                this.showMessage(false, '[Error] Löschen eines Benutzers fehlgeschlagen')
            }
        },
    }
}