export default function typeUserFilter(value) {

    if (value === 'manager') {
        value = 'Manager'
    }

    if (value === 'admin') {
        value = 'Director'
    }
    return value
}