<template>
  <div>
    <div v-if="isPopupTask" @click="$emit('closePopup')" class="mask"></div>
    <transition name="popup">
      <div v-if="isPopupTask" class="popup">
        <button @click="$emit('closePopup')" class="popup__close-btn"></button>
        <h2 v-if="isPopupEditTask">Edit Task</h2>
        <h2 v-else>New Task</h2>
        <div style="position: relative">
          <base-field-input label="Kunden suchen">
            <input :disabled="$route.name === 'customer_page'"
                   :class="{'disabled-input': $route.name === 'customer_page'}"
                   v-model="searchCustomersField"
                   @input="$emit('searchCustomersInPopup')" placeholder="Kunden suchen"
                   type="text">
          </base-field-input>
          <transition name="opacity">
            <div v-if="customersForDropdown.length" class="popup__search-dropdown">
              <a @click.prevent="$emit('editIdNumberCustomer')"
                 :key="'dropdown' + customer.id" v-for="customer in customersForDropdown"
                 href="#">{{ customer.surname }} {{ customer.name }}</a>
            </div>
          </transition>
        </div>
        <div>
          <base-field-input label="Suche nach Kundennummer">
            <input @change="$emit('getCountersInCustomer')" disabled class="disabled-input"
                   placeholder="Suche nach Kundennummer" type="text"
                   v-model="newTask.customer_id">
          </base-field-input>
          <div v-if="($v.newTask.customer_id.$dirty && !$v.newTask.customer_id.required)"
               class="popup-customer__error">
            This field is mandatory
          </div>
        </div>
        <div>
          <base-field-input label="Aufgabe"
                            :src_img="require(`@/assets/images/popup-type_icon.svg`)">
            <input
              style="padding-left: 64px;" type="text"
              v-model="newTask.type_of_task"
              placeholder="Aufgabe"
              :disabled="user.user_type[0].user_type === 'manager'"
              :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
            >
            <field-error-required
              v-if="($v.newTask.type_of_task.$dirty && !$v.newTask.type_of_task.required)"/>
          </base-field-input>
        </div>
        <div>
          <base-field-input label="Zählernummer"
                            :src_img="require(`@/assets/images/task-counter_icon.svg`)">
            <v-select
              placeholder="Zählernummer"
              class="vSelect"
              v-model="newTask.counter_number"
              :options="countersInCustomerComputed"
              :disabled="user.user_type[0].user_type === 'manager'"
              :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
            ></v-select>
            <field-error-required
              v-if="($v.newTask.counter_number.$dirty && !$v.newTask.counter_number.required)"
            />
          </base-field-input>
        </div>
        <div class="create-customer__block_50_50">
          <div>
            <base-field-input label="Ausführender Mitarbeiter"
                              :src_img="require('@/assets/images/popup-assign_icon.svg')">
              <v-select
                placeholder="Ausführender Mitarbeiter"
                class="vSelect"
                v-model="newTask.user_id"
                :options="userOptions"
                label="label"
                index="index"
                :reduce="option => option.index"
                :disabled="user.user_type[0].user_type === 'manager'"
                :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
              ></v-select>
            </base-field-input>
            <div v-if="($v.newTask.user_id.$dirty && !$v.newTask.user_id.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </div>
          <div>
            <base-field-input label="Deadline"
                              :src_img="require('@/assets/images/popup-deadline_icon.svg')">
              <input
                style="padding-left: 62px;"
                type="text"
                v-model="newTask.deadline"
                @input="validation('newTask', 'deadline')"
                placeholder="Date (DD.MM.YYYY)"
                @keydown="filterInput"
                :disabled="user.user_type[0].user_type === 'manager'"
                :class="{'disabled-input': user.user_type[0].user_type === 'manager'}"
              >
            </base-field-input>
            <div v-if="($v.newTask.deadline.$dirty && !$v.newTask.deadline.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </div>
        </div>
        <div class="create-customer__block_50_50">
          <div></div>
          <div>
            <base-field-input label="">
              <v-select
                class="vSelect"
                v-model="newTask.status"
                :options="statusOptions"
                label="label"
                index="index"
                :reduce="option => option.index"
                :class="{'active-select': newTask.status === 'Completed', 'closed-select': newTask.status === 'Cancelled', 'opened-select': newTask.status === 'Opened'}"
              ></v-select>
            </base-field-input>
          </div>
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          <button v-if="isPopupEditTask" @click.prevent="$emit('editTask')" class="blue-button">
            <span></span>
            Aufgabe bearbeiten
          </button>
          <button v-else @click.prevent="$emit('createTask')" class="blue-button">
            <span></span>
            Neue Aufgabe
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomSelect from "@/components/fields/CustomSelect.vue";
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import taskValidations from '@/mixins/tasks/validations'
import eventDate from "@/mixins/eventDate";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
  name: "createTask",
  components: {vSelect, BaseFieldInput, fieldErrorRequired, CustomSelect, Datepicker},
  mixins: [taskValidations, eventDate],
  props: {
    user: {},
    isPopupTask: Boolean,
    isPopupEditTask: Boolean,
    isPopupTypeOfTask: Boolean,
    searchCustomersField: {
      value: String
    },
    customersForDropdown: Array,
    newTask: {
      customer_id: String,
      type_of_task: String,
      counter_number: String,
      user_id: String,
      deadline: String,
      status: String
    },
    countersInCustomerComputed: Array,
    type_of_tasks: Array,
    users: Array,
    statusOptions: Array,
    userOptions: Array
  },
  methods: {
    callValidation() {
      this.$v.create_user.$touch()
    },
    searchTypeOfTask(event) {
      this.$emit('searchTypeOfTask', event)
    },
    searchCounters(event) {
      this.$emit('searchCounters', event)
    }
  }
}
</script>


<style lang="stylus">

</style>