<template>
  <div>
    <div v-if="isPopupTypeOfTask" @click="$emit('closePopup')" class="mask"></div>
    <transition v-if="isPopupTypeOfTask" name="popup">
      <div class="popup">
        <button @click="$emit('closePopup')" class="popup__close-btn"></button>
        <h2 v-if="isPopupEditTypeOfTask">Art der Aufgaben bearbeiten</h2>
        <h2 v-else>Art der Aufgaben erstellen</h2>
        <div class="mt-24 w100">
          <base-field-input label="Aufgabe" :src_img="require('@/assets/images/popup-type_icon.svg')">
            <input class="show-icon" placeholder="Aufgabe" type="text" v-model="type_of_task.value">
          </base-field-input>
<!--          <div v-if="($v.type_of_task.$dirty && !$v.type_of_task.required)"-->
<!--               class="popup-customer__error">-->
<!--            Dieses Feld ist obligatorisch-->
<!--          </div>-->
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          <button @click.prevent="$emit('actionTypeOfTask')" class="blue-button">
            <span></span>
            <p v-if="isPopupEditTypeOfTask">Art der Aufgabe bearbeiten</p>
            <p v-else>Art der Aufgabe erstellen</p>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import validations from "@/mixins/settings/validations";

export default {
  name: "createTypeOfTask",
  components: {BaseFieldInput},
  mixins: [validations],
  props: {
    isPopupTypeOfTask: Boolean,
    isPopupEditTypeOfTask: Boolean,
    type_of_task: {
      value: String
    }
  },
  methods: {
    callValidation() {
      this.$v.type_of_task.$touch()
    },
  }
}
</script>

<style lang="stylus">

</style>