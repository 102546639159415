<template>
  <header class="header">
    <div class="header__input">
      <img src="../../assets/images/header-search_icon.svg" alt="">
      <input ref="searchInput" placeholder="Suche" type="text">
    </div>
    <div class="header__actions">
      <div class="header__info">
        <div @click="logout" class="header__main-info">
          <div>
            <span>{{ transformEmployee(user.user.first_name, user.user.last_name) }}</span>
            <img src="" alt="">
          </div>
          {{ user.user.first_name }} {{ user.user.last_name }}
          <span class="arrow"></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import transformEmployee from "@/mixins/transformEmployee";
import getUser from "@/mixins/getUser";
export default {
  name: "baseHeader",
  mixins: [transformEmployee, getUser],
  data() {
    return {
      showDropdown: false,
      image: ''
    }
  },
  mounted() {
    console.log(this.user)
  },
  methods: {
    logout() {
      localStorage.removeItem('auth_token')
      this.$router.push({name: 'auth_page'})
    }
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.header
  max-width 100%
  width: 100%
  padding: 28px 2vw 28px 0
  display flex
  justify-content space-between
  align-items center
  &__input
    position relative
    max-width 450px
    width: 100%
    input
      height: 48px
      border-radius 8px
      border 1px solid
      border-color #E6E6E6
      outline none
      background none
      padding-left: 44.5px
      &::placeholder
        color #B3B3B3
    img
      position absolute
      left: 16px
      top: 50%
      transform translateY(-50%)
      z-index -1

  &__actions
    display flex
    align-items center

  &__notification
    display flex
    align-items center
    justify-content center
    background #F0F0F0
    border-radius 4px
    width: 40px
    height: 40px

  &__info
    margin-left: 32px
    position relative


  &__main-info
    display flex
    align-items center
    font-size: 20px
    font-weight: 500
    cursor pointer
    div
      background #f0f0f0
      display flex
      align-items center
      justify-content center
      overflow hidden
      border-radius 4px
      margin-right: 12px
      width: 40px
      height: 40px
      img
        width: 40px
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
    .arrow
      margin-left: 8px





</style>
