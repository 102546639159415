<template>
  <div>
    <div v-if="isPopupUser" @click="$emit('closePopup')" class="mask"></div>
    <transition v-if="isPopupUser" name="popup">
      <div class="popup">
        <button @click="$emit('closePopup')" class="popup__close-btn"></button>
        <h2 v-if="isPopupEditUser">Benutzer bearbeiten</h2>
        <h2 v-else>Benutzer erstellen</h2>
        <div class="mt-24 w100">
          <base-field-input label="Benutzername" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Benutzername" type="text" v-model="createUser.username">
            <div v-if="($v.create_user.username.$dirty && !$v.create_user.username.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="mt-24 w100">
          <base-field-input label="Name" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Name" type="text" v-model="createUser.first_name">
            <div v-if="($v.create_user.first_name.$dirty && !$v.create_user.first_name.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="mt-24 w100">
          <base-field-input label="Vorname" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Vorname" type="text" v-model="createUser.last_name">
            <div v-if="($v.create_user.last_name.$dirty && !$v.create_user.last_name.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="mt-24 w100">
          <base-field-input label="Passwort" :src_img="require('@/assets/images/settings-password_icon.svg')">
            <input class="show-icon" placeholder="Passwort" type="text" v-model="createUser.password">
            <div v-if="($v.create_user.password.$dirty && !$v.create_user.password.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="settings__upload-image">
          Bild hochladen
          <button @click="$emit('openSystemFile')" class="blue-button">
            <img src="@/assets/images/documents-upload_icon.svg" alt="">
            Hochladen
          </button>
          <span style="max-width: 200px; white-space: nowrap; overflow: hidden;">{{ nameImage }}</span>
          <input style="display: none" ref="image" @change="$emit('handleFileChange')" type="file">
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="$emit('closePopup')" href="#">Cancel</a>
          <button v-if="isPopupEditUser" @click.prevent="$emit('editUser')" class="blue-button">
            <span></span>
            Benutzer bearbeiten
          </button>
          <button v-else @click.prevent="$emit('createUser')" class="blue-button">
            <span></span>
            Neuer Benutzer
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import validations from "@/mixins/settings/validations";

export default {
  name: 'createUser',
  components: {BaseFieldInput},
  mixins: [validations],
  props: {
    isPopupUser: Boolean,
    isPopupEditUser: Boolean,
    createUser: {
      first_name: String,
      last_name: String,
      name: String,
      password: String,
      image: String
    },
    nameImage: String
  },
  methods: {
    callValidation() {
      this.$v.create_user.$touch()
    },
    getInputImage() {
      return this.$refs.image;
    }
  }
}
</script>

<style lang="stylus">

</style>