class baseService {
    constructor(method, urlMethod, headers = null, file = false) {
        this.method = method;
        this.backenUrl = process.env.VUE_APP_BACKEND_URL;
        this.urlMethod = urlMethod;
        this.headers = headers
        this.file = file
    }

    async callMethod(data = null) {
        const options = {}
        let body = undefined
        if (data !== null && !this.file ) {
            body = JSON.stringify(data)
            options.headers = {'Content-Type': 'application/json'}
        } else if (data !== null && this.file) {
            body = data
        }

        options.method = this.method
        options.body = body

        return await fetch(this.backenUrl + this.urlMethod, options)
    }
}

class GetCustomers extends baseService {
    method = 'POST'
    urlMethod = 'get_customers/'
}

class CreateCustomer extends baseService {
    method = 'POST'
    urlMethod = 'create_customer/'
    file = true
}

class DeleteCustomers extends baseService {
    method = 'POST'
    urlMethod = 'delete_customers/'
}

class GetCustomer extends baseService {
    method = 'POST'
    urlMethod = 'get_customer/'
}

class AuthorizeUser extends baseService {
    method = 'POST'
    urlMethod = 'authorize_user/'
}

class Authenticate extends baseService {
    method = 'POST'
    urlMethod = 'authenticate/'
}

class GetUsers extends baseService {
    method = 'POST'
    urlMethod = 'get_users/'
}

class CreateTask extends baseService {
    method = 'POST'
    urlMethod = 'create_task/'
}

class GetTasks extends baseService {
    method = 'POST'
    urlMethod = 'get_tasks/'
}

class EditStatusTask extends baseService {
    method = 'POST'
    urlMethod = 'edit_status_task/'
}

class CreateUser extends baseService {
    method = 'POST'
    urlMethod = 'create_user/'
    file = true
}

class DeleteTasks extends baseService {
    method = 'POST'
    urlMethod = 'delete_tasks/'
}

class SearchTasks extends baseService {
    method = 'POST'
    urlMethod = 'search_by_tasks/'
}

class CreateDocument extends baseService {
    method = 'POST'
    urlMethod = 'create_document/'
    file = true
}

class SearchCustomers extends baseService {
    method = 'POST'
    urlMethod = 'search_by_customers/'
}

class GetDocuments extends baseService {
    method = 'POST'
    urlMethod = 'get_documents/'
}

class CreateTypeOfTask extends baseService {
    method = 'POST'
    urlMethod = 'create_type_of_task/'
}

class GetTypeOfTask extends baseService {
    method = 'POST'
    urlMethod = 'get_type_of_tasks/'
}

class EditTypeOfTask extends baseService {
    method = 'POST'
    urlMethod = 'edit_type_of_task/'
}

class DeleteDocument extends baseService {
    method = 'POST'
    urlMethod = 'delete_document/'
}

class EditTask extends baseService {
    method = 'POST'
    urlMethod = 'edit_task/'
}

class EditTasks extends baseService {
    method = 'POST'
    urlMethod = 'edit_tasks/'
}

class EditUser extends baseService {
    method = 'POST'
    urlMethod = 'edit_user/'
    file = true
}

class DeleteUser extends baseService {
    method = 'POST'
    urlMethod = 'delete_user/'
}

class DeleteTypeOfTask extends baseService {
    method = 'POST'
    urlMethod = 'delete_type_of_task/'
}

class EditCustomer extends baseService {
    method = 'POST'
    urlMethod = 'edit_customer/'
    file = true
}

class SearchCustomersInPopup extends baseService {
    method = 'POST'
    urlMethod = 'search_by_customers_in_popup/'
}

class GetCountersInCustomer extends baseService {
    method = 'POST'
    urlMethod = 'get_counters_in_customer/'
}

class SearchCountersInTask extends baseService {
    method = 'POST'
    urlMethod = 'search_by_counters_in_task/'
}

class CreateOperator extends baseService {
    method = 'POST'
    urlMethod = 'create_operator/'
}

class GetOperators extends baseService {
    method = 'POST'
    urlMethod = 'get_operators/'
}

class EditOperator extends baseService {
    method = 'POST'
    urlMethod = 'edit_operator/'
}

class DeleteOperator extends baseService {
    method = 'POST'
    urlMethod = 'delete_operator/'
}

class GetOperatorsInDropdown extends baseService {
    method = 'POST'
    urlMethod = 'get_operators_in_dropdown/'
}

class GetFolders extends baseService {
    method = 'POST'
    urlMethod = 'get_folder_document/'
}

class CreateFolder extends baseService {
    method = 'POST'
    urlMethod = 'create_folder_document/'
}

class DeleteFolder extends baseService {
    method = 'POST'
    urlMethod = 'delete_folder_document/'
}

class GetStatistics extends baseService {
    method = 'POST'
    urlMethod = 'get_statistics/'
}

class SearchOperatorsInDropdown extends baseService {
    method = 'POST'
    urlMethod = 'search_operators_in_dropdown/'
}

class SearchFolders extends baseService {
    method = 'POST'
    urlMethod = 'search_folder_document/'
}

class SearchCounters extends baseService {
    method = 'POST'
    urlMethod = 'search_by_counters/'
}

class GetCounters extends baseService {
   method = 'POST'
   urlMethod = 'get_counters/'
}

class GetContracts extends baseService {
    method = 'POST'
    urlMethod = 'get_contracts/'
}

class GetCongratulationTemplate extends baseService {
    method = 'GET'
    urlMethod = 'get_congratulation_template/'
}

class EditCongratulationTemplate extends baseService {
    method = 'POST'
    urlMethod = 'edit_congratulation_template/'
}

class SearchTypeOfTask extends baseService {
    method = 'POST'
    urlMethod = 'search_by_type_of_task/'
}

class DeleteTaskBot extends baseService {
    method = 'POST'
    urlMethod = 'delete_task_bot/'
}

class GetTaskBot extends baseService {
    method = 'POST'
    urlMethod = 'get_task_bot/'
}

class CreateTaskBot extends baseService {
    method = 'POST'
    urlMethod = 'create_task_bot/'
}

class EditTaskBot extends baseService {
    method = 'POST'
    urlMethod = 'update_task_bot/'
}

class GetMessages extends baseService {
    method = 'POST'
    urlMethod = 'get_messages/'
}

class GetContractsInGenerateDocument extends baseService {
    method = 'POST'
    urlMethod = 'get_contract/'
}

class DocumentGeneration extends baseService {
    method = 'POST'
    urlMethod = 'document_generation/'
}

class GetOperatorsInContract extends baseService {
    method = 'POST'
    urlMethod = 'get_operators_in_contract/'
}

class GetDocumentsGeneration extends baseService {
    method = 'POST'
    urlMethod = 'get_documents_generate/'
}

class DeleteGenerateDocument extends baseService {
    method = 'POST'
    urlMethod = 'delete_document_generate/'
}


export default {
    install(Vue) {
        Vue.prototype.$services = {
            'getCustomers': new GetCustomers(),
            'getCustomer': new GetCustomer(),
            'getUsers': new GetUsers(),
            'getTasks': new GetTasks(),
            'getTypeOfTask': new GetTypeOfTask(),
            'getDocuments': new GetDocuments(),
            'getCountersInCustomer': new GetCountersInCustomer(),
            'getOperators': new GetOperators(),
            'getOperatorsInDropdown': new GetOperatorsInDropdown(),
            'getCongratulationTemplate': new GetCongratulationTemplate(),
            'getTaskBot': new GetTaskBot(),
            'getMessages': new GetMessages(),
            'createCustomer': new CreateCustomer(),
            'createTask': new CreateTask(),
            'createUser': new CreateUser(),
            'createDocument': new CreateDocument(),
            'createTypeOfTask': new CreateTypeOfTask(),
            'createOperator': new CreateOperator(),
            'createTaskBot': new CreateTaskBot(),
            'deleteCustomers': new DeleteCustomers(),
            'deleteTasks': new DeleteTasks(),
            'deleteDocument': new DeleteDocument(),
            'deleteUser': new DeleteUser(),
            'deleteTypeOfTask': new DeleteTypeOfTask(),
            'deleteOperator': new DeleteOperator(),
            'deleteTaskBot': new DeleteTaskBot(),
            'authorizeUser': new AuthorizeUser(),
            'authenticate': new Authenticate(),
            'searchTasks': new SearchTasks(),
            'searchCustomers': new SearchCustomers(),
            'editStatusTask': new EditStatusTask(),
            'editTypeOfTask': new EditTypeOfTask(),
            'editTask': new EditTask(),
            'editTasks': new EditTasks(),
            'editUser': new EditUser(),
            'editCustomer': new EditCustomer(),
            'editOperator': new EditOperator(),
            'editCongratulationTemplate': new EditCongratulationTemplate(),
            'editTaskBot': new EditTaskBot(),
            'searchCustomersInPopup': new SearchCustomersInPopup(),
            'searchCounters': new SearchCounters(),
            'searchCountersInTask': new SearchCountersInTask(),
            'getFolders': new GetFolders(),
            'createFolder': new CreateFolder(),
            'deleteFolder': new DeleteFolder(),
            'getStatistics': new GetStatistics(),
            'searchOperatorsInDropdown': new SearchOperatorsInDropdown(),
            'searchFolders': new SearchFolders(),
            'getCounters': new GetCounters(),
            'getContracts': new GetContracts(),
            'searchTypeOfTask': new SearchTypeOfTask(),
            'getContractsInGenerateDocument': new GetContractsInGenerateDocument(),
            'documentGeneration': new DocumentGeneration(),
            'getDocumentsGeneration': new GetDocumentsGeneration(),
            'getOperatorsInContract': new GetOperatorsInContract(),
            'deleteGenerateDocument': new DeleteGenerateDocument()
        }
    }
}
