import Vue from 'vue'
import VueRouter from 'vue-router'
import TasksPage from '@/pages/tasks_page'
import CustomersPage from '@/pages/customers_page'
import CustomerPage from '@/pages/customer_page'
import AuthPage from '@/pages/auth_page'
import SettingsPage from '@/pages/settings_page'
import DashboardPage from '@/pages/dashboard_page'
import DocumentsPage from '@/pages/documents_page'
import TelegramBot from "@/pages/telegram-bot_page.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/auth/',
        name: 'auth_page',
        component: AuthPage,
        meta: {
            title: 'Energieverwaltung - Autorisierung'
        }
    },
    {
        path: '/',
        name: 'dashboard_page',
        component: DashboardPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Statistik'
        }
    },
    {
        path: '/tasks/',
        name: 'tasks_page',
        component: TasksPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Aufgaben'
        }
    },
    {
        path: '/customers/',
        name: 'customers_page',
        component: CustomersPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Kunden'
        }
    },
    {
        path: '/customers/:id',
        name: 'customer_page',
        component: CustomerPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Kunde #id'
        }
    },
    {
        path: '/settings/',
        name: 'settings_page',
        component: SettingsPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Einstellungen'
        }
    },
    {
        path: '/customers/:id/:folder',
        name: 'documents_page',
        component: DocumentsPage,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - folder'
        }
    },
    {
        path: '/telegram-bot/',
        name: 'telegram-bot_page',
        component: TelegramBot,
        meta: {
            requiresAuth: true,
            title: 'Energieverwaltung - Telegram bot'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
