<template>
  <base_layout>
    <transition name="opacity">
      <message @resetMessage="resetMessage" v-if="isMessage" :success="successMessage" :message="addMessage"/>
    </transition>
    <div class="content">

      <div class="white-container settings__users">
        <div v-if="mainUserLoader" :class="{'fadein-animation': !mainUserLoader}" class="big-loader loader">
          <span></span></div>
        <div :class="{'fadein-animation': !mainUserLoader}" style="height: 100%;" v-else>
          <div class="customer__table-title-and-buttons">
            <h3>Benutzer</h3>
            <div class="group-btn" v-if="user.user_type[0].user_type === 'admin'">
              <button @click.prevent="isPopupUser = true" class="blue-button">
                <span></span>Neuer Benutzer
              </button>
            </div>
          </div>
          <div class="customer__wrap-table settings__table" ref="scrollContainer">
            <table cellspacing="0" cellpadding="0" class="table">
              <thead>
              <tr>
                <td class="text-transform-uppercase">Avatar</td>
                <td class="text-transform-uppercase">Benutzername</td>
                <td class="text-transform-uppercase">Vorname</td>
                <td class="text-transform-uppercase">Name</td>
                <td v-if="user.user_type[0].user_type === 'admin'" class="text-transform-uppercase">Aktion</td>
              </tr>
              </thead>
              <tbody>
              <tr :class="{'tr-checked': userTable.isChecked}"
                  v-for="(userTable, index) in users" :key="'user_' + index">
                <td>
                  <div class="table__column-employee">
                    <span>{{ transformEmployee(userTable.first_name, userTable.last_name) }}</span>
                  </div>
                </td>
                <td>
                  <a
                    v-if="user.user_type[0].user_type === 'admin' || user.user.username === userTable.username"
                    @click.prevent="editOpenUserPopup(userTable)"
                    href="#"
                  >{{ userTable.username }}</a>
                  <span v-else>{{ userTable.username }}</span>
                </td>
                <td>{{ userTable.last_name }}</td>
                <td>{{ userTable.first_name }}</td>
                <td v-if="user.user_type[0].user_type === 'admin'" class="table__column-action">
                  <a @click.prevent="deleteWindowUser = index" style="position: relative; z-index: 1;" href="#">
                    <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                  </a>
                  <div v-if="deleteWindowUser === index" class="warning-delete">
                    Sind Sie sicher, dass Sie den Client entfernen möchten?
                    <div>
                      <button @click.prevent="deleteUser(userTable.id)">Ja</button>
                      <button @click.prevent="deleteWindowUser = null">Nein</button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="white-container settings__operators">
        <div v-if="mainOperatorLoader" :class="{'fadein-animation': !mainOperatorLoader}"
             class="big-loader loader">
          <span></span></div>
        <div :class="{'fadein-animation': !mainOperatorLoader}" style="height: 100%;">
          <div class="customer__table-title-and-buttons">
            <h3>Betreiber</h3>
            <div class="group-btn">
              <button @click.prevent="showPopup('isPopupOperator')" class="blue-button">
                <span></span>Neuer Betreiber
              </button>
            </div>
          </div>
          <div class="customer__wrap-table settings__table" ref="scrollContainerOperators"
               @scroll="handleScroll('', 'debounceGetOperators', 'scrollContainerOperators', checkHeightOperators, searchOperators, 'isLoadingOperators')"
          >
            <table v-if="operators.length !== 0" cellspacing="0" cellpadding="0" class="table">
              <thead>
              <tr>
                <td class="text-transform-uppercase">NAME</td>
                <td class="text-transform-uppercase">ADRESSE</td>
                <td class="text-transform-uppercase">MOBIL</td>
                <td class="text-transform-uppercase">E-MAIL</td>
                <td class="text-transform-uppercase">AKTION</td>
              </tr>
              </thead>
              <tbody>
              <tr :class="{'tr-checked': operator.isChecked}" v-for="(operator, index) in operators"
                  :key="'task_' + index">
                <td>
                  <a @click.prevent="editOpenOperator(operator)" href="#">
                    {{ operator.operator_name }}
                  </a>
                </td>
                <td>
                  {{ operator.operator_street }}, {{ operator.operator_house_number }} <br>
                  {{ operator.operator_postcode }} {{ operator.operator_city }}
                </td>
                <td>{{ operator.operator_phone }}</td>
                <td>{{ operator.operator_email }}</td>
                <td class="table__column-action">
                  <a @click.prevent="deleteWindowOperator = index" style="position: relative; z-index: 1;" href="#">
                    <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                  </a>
                  <div v-if="deleteWindowOperator === index" class="warning-delete">
                    Sind Sie sicher, dass Sie den Client entfernen möchten?
                    <div>
                      <button @click.prevent="deleteOperator(operator.id)">Ja</button>
                      <button @click.prevent="deleteWindowOperator = null">Nein</button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
            <div v-if="isLoadingOperators" class="loader"><span></span></div>
          </div>
        </div>
      </div>
      <!--            <div class="white-container settings__tasks">-->
      <!--                <div v-if="mainUserLoader" :class="{'fadein-animation': !mainTypeOfTaskLoader}"-->
      <!--                     class="big-loader loader">-->
      <!--                    <span></span></div>-->
      <!--                <div :class="{'fadein-animation': !mainUserLoader}" style="height: 100%;">-->
      <!--                    <div class="customer__table-title-and-buttons">-->
      <!--                        <h3>Art der Aufgaben</h3>-->
      <!--                        <div class="group-btn">-->
      <!--                            <button @click.prevent="isPopupTypeOfTask = true" class="blue-button">-->
      <!--                                <span></span>Neue Art der Aufgabe-->
      <!--                            </button>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                    <div class="customer__wrap-table settings__table" ref="scrollContainerTypeOfTask"-->
      <!--                         @scroll="handleScroll('', 'debounceGetTypeOfTask', 'scrollContainerTypeOfTask', checkHeightTypeOfTask, searchTypeOfTask, 'isLoadingTypeOfTask')"-->
      <!--                    >-->
      <!--                        <table v-if="type_of_tasks.length !== 0" cellspacing="0" cellpadding="0" class="table">-->
      <!--                            <thead>-->
      <!--                            <tr>-->
      <!--                                <td>Aufgabe</td>-->
      <!--                                <td>Menge</td>-->
      <!--                                <td>Aktion</td>-->
      <!--                            </tr>-->
      <!--                            </thead>-->
      <!--                            <tbody>-->
      <!--                            <tr :class="{'tr-checked': task.isChecked}" v-for="(task, index) in type_of_tasks"-->
      <!--                                :key="'task_' + index">-->
      <!--                                <td style="width: 40%;"><a @click.prevent="editOpenTypeOfTask(task)" href="#">{{-->
      <!--                                        task.type_of_tasks-->
      <!--                                    }}</a>-->
      <!--                                </td>-->
      <!--                                <td>3</td>-->
      <!--                                <td class="table__column-action">-->
      <!--                                    <img style="cursor: pointer" @click.prevent="deleteTypeOfTask(task.id)"-->
      <!--                                         src="@/assets/images/trash_icon.svg" alt="">-->
      <!--                                </td>-->
      <!--                            </tr>-->
      <!--                            </tbody>-->
      <!--                        </table>-->
      <!--                        <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>-->
      <!--                        <div v-if="isLoadingTypeOfTask" class="loader"><span></span></div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <create-user
      :is-popup-edit-user="isPopupEditUser"
      :is-popup-user="isPopupUser"
      :create-user="create_user"
      :name-image="nameImage"
      @createUser="createUser"
      @editUser="editUser"
      @closePopup="closePopupUserAndTypeTask"
      @handleFileChange="handleFileChange"
      @openSystemFile="openSystemFile"
      ref="createUser"
    />
    <create-type-of-task
      :is-popup-edit-type-of-task="isPopupEditTypeOfTask"
      :is-popup-type-of-task="isPopupTypeOfTask"
      :type_of_task="type_of_task"
      @closePopup="closePopupUserAndTypeTask"
      @actionTypeOfTask="actionTypeOfTask"
    />
    <create-operator
      :operator="operator"
      :is-popup-edit-operator="isPopupEditOperator"
      :is-popup-operator="isPopupOperator"
      @closePopup="closePopup"
      @createOperator="createOperator"
      @editOperator="editOperator"
    />
  </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout";

// MIXINS
import transformEmployee from "@/mixins/transformEmployee";
import togglePopup from "@/mixins/togglePopup";
import success_error_state from "@/mixins/success_error_state";
import eventsOperator from "@/mixins/operators/events";
import lazyLoadScrollTable from "@/mixins/lazyLoadScrollTable";
import typeOfTasks from "@/mixins/settings/typeOfTasks";
import users from "@/mixins/settings/users";
import validations from "@/mixins/settings/validations";

// COMPONENTS
import createOperator from "@/components/popups/createOperator.vue";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import message from "@/components/message";
import CreateTypeOfTask from "@/components/popups/createTypeOfTask.vue";
import CreateUser from "@/components/popups/createUser.vue";
import getUser from "@/mixins/getUser";

export default {
  name: "settings_page",
  components: {CreateUser, CreateTypeOfTask, Base_layout, BaseFieldInput, BaseCheckbox, message, createOperator},
  mixins: [transformEmployee, togglePopup, success_error_state, eventsOperator, lazyLoadScrollTable, typeOfTasks, users, validations, getUser],
  data() {
    return {
      isPopupEditOperator: false,
      isPopupOperator: false,
      nameImage: '',
      users: null,
    }
  },
  methods: {
    closePopupUserAndTypeTask() {
      this.isPopupEditTypeOfTask = false
      this.isPopupTypeOfTask = false
      this.isPopupEditUser = false
      this.isPopupUser = false
      this.type_of_task.value = ''
      this.type_of_task_id = ''
      this.create_user = {
        username: '',
        password: '',
        last_name: '',
        first_name: '',
      }
    },
  },
  async mounted() {
    await this.getUsers()
    this.mainUserLoader = false
  }
}
</script>

<style lang="stylus">
.settings
  &__table
    max-height calc(40vh - 100px)

  &__users
    min-height: 40vh
    max-height: 60vh
    margin-bottom: 32px
    position relative

  &__upload-image
    margin-top: 16px
    display flex
    align-items center
    font-size: 18px

    button
      margin-left: 16px

    span
      margin-left: 16px
      font-size: 20px
      font-weight: 700

  &__tasks
    min-height 40vh
    max-height: 50vh
    position relative

  &__operators
    min-height 40vh
    max-height: 50vh
    position relative
    margin-bottom: 32px

</style>
