<template>
  <div class="container">
    <div style="display: flex">
      <base-sidebar/>
      <div style="display: flex; flex-direction: column; width: 100%; padding-right: 2.8vw;">
        <header class="header">
          <div class="header__input">
            <img v-if="$route.name !== 'settings_page'" src="@/assets/images/header-search_icon.svg" alt="">
            <input v-if="$route.name !== 'settings_page'" @input="$emit('searchInput')" placeholder="Search" type="text">
          </div>
          <div class="header__actions">
            <div class="header__info">
              <div @click="logout" class="header__main-info">
                <div>
                  <span>{{
                      transformEmployee(user.user.first_name, user.user.last_name)
                    }}</span>
                </div>
                {{ user.user.first_name }} {{ user.user.last_name }}
                <span class="arrow"></span>
              </div>
            </div>
          </div>
        </header>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSidebar from "@/components/elements/baseSidebar.vue";
import BaseHeader from "@/components/elements/baseHeader.vue";
import transformEmployee from "@/mixins/transformEmployee";
import getUser from "@/mixins/getUser";

export default {
  name: "base_layout",
  components: {BaseHeader, BaseSidebar},
  mixins: [transformEmployee, getUser],
  data() {
    return {
      showDropdown: false,
      image: ''
    }
  },
  created() {
    this.image = this.$store.state.staticUrl + this.user.user_type[0].image_url
  },
  methods: {
    logout() {
      localStorage.removeItem('auth_token')
      this.$router.push({name: 'auth_page'})
    }
  }
}
</script>

<style lang="stylus">

</style>
