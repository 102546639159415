export default {
    data() {
        return {
            checkHeight: false,
            search: false,
        }
    },
    methods: {
        async handleScroll(funcIsSearch, funcNoSearch, scrollContainer = 'scrollContainer', checkHeight = this.checkHeight, search = this.search, loading = 'isLoading') {
            let container
            if (typeof scrollContainer === 'string') {
                container = this.$refs[scrollContainer];
            } else {
                container = scrollContainer
            }
            let sum = Math.round(container.scrollTop) + Math.round(container.clientHeight)
            if (!checkHeight && sum >= (container.scrollHeight - 1)) {
                this[loading] = true
                if (search) {
                    this[funcIsSearch]()
                } else {
                    this[funcNoSearch]()
                }
            }
        },
    }
}
