export function handle(messageName, typeMessage) {
    return function (target, property, descriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args) {
            const response = await originalMethod.apply(this, ...args);
            console.log(args)
            // if (response.status === 200) {
            this.showMessage(typeMessage, messageName)
            // } else {
            //     this.showMessage(false, '[ERROR] - failed to receive data from server')
            // }

            return await originalMethod.call(this, ...args)
        };

        return descriptor;
    }
}
