<template>
  <div>
    <div class="checkbox">
      <input :checked="valCheckbox" @click="$emit('click', $event.target.value)" :id="id" type="checkbox">
      <label :for="id"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    valCheckbox: Boolean,
    id: String
  },

}
</script>

<style scoped>

</style>