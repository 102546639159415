import {debounce} from "lodash";

export default {
    data() {
        return {
            type_of_task: {
                value: ''
            },
            type_of_task_id: '',
            type_of_tasks: [],
            isPopupTypeOfTask: false,
            isPopupEditTypeOfTask: false,
            countNextPageTypeOfTask: 1,
            isLoadingTypeOfTask: false,
            checkHeightTypeOfTask: false,
            searchTypeOfTask: false,
            mainTypeOfTaskLoader: true,
        }
    },
    methods: {
        async getTypeOfTask() {
            try {
                let response = await this.$services.getTypeOfTask.callMethod({page: 1})
                this.type_of_tasks = []
                this.type_of_tasks = await response.json()
            } catch (e) {
            }
        },
        async getPaginationTypeOfTask() {
            try {
                const response = await this.$services.getTypeOfTask.callMethod({page: this.countNextPageTypeOfTask})
                const result = await response.json()
                this.type_of_tasks = [...this.type_of_tasks, ...result]
                this.countNextPageTypeOfTask++
            } catch (e) {
                this.isLoadingTypeOfTask = false
                this.checkHeightTypeOfTask = true
            }
        },
        async editOpenTypeOfTask(task) {
            this.isPopupEditTypeOfTask = true
            this.isPopupTypeOfTask = true
            this.type_of_task.value = task.type_of_tasks
            this.type_of_task_id = task.id
        },
        async actionTypeOfTask() {
            try {
                if (this.isPopupEditTypeOfTask) {
                    let response = await this.$services.editTypeOfTask.callMethod({
                        type_of_task: this.type_of_task.value,
                        id: this.type_of_task_id
                    })
                    let result = await response.json()
                    if (result.status === 'error') {
                        this.showMessage(false, '[Error] Bearbeiten einer Aufgabe fehlgeschlagen')
                    } else {
                        this.showMessage(true, 'Der Typ der Aufgabe wurde erfolgreich erstellt')
                    }
                } else {
                    await this.$services.createTypeOfTask.callMethod({type_of_task: this.type_of_task.value})
                    this.showMessage(true, 'Der Typ der Aufgabe wurde erfolgreich bearbeitet')
                }
                await this.getTypeOfTask()
            } catch (e) {
                this.showMessage(false, '[Error] Bearbeiten einer Aufgabe fehlgeschlagen')
                console.error('[ERROR] ' + e.status + ' Typ der Aufgabe konnte nicht erstellt werden')
            }
            this.isPopupEditTypeOfTask = false
            this.isPopupTypeOfTask = false
            this.type_of_task.value = ''
            this.type_of_task_id = ''
        },
        async deleteTypeOfTask(id) {
            try {
                let response = await this.$services.deleteTypeOfTask.callMethod({id: id})
                if (await response.json().status === 'error') {
                    this.showMessage(false, '[Error] Löschen eines Aufgabentyps fehlgeschlagen')
                } else {
                    this.showMessage(true, 'Der Typ der Aufgabe wurde erfolgreich gelöscht')
                }
                await this.getTypeOfTask()
            } catch (e) {
                this.showMessage(false, '[Error] Löschen eines Aufgabentyps fehlgeschlagen')
            }
        },
    },
    async mounted() {
        this.debounceGetTypeOfTask = debounce(this.getPaginationTypeOfTask, 200)
        await this.getPaginationTypeOfTask()
        this.mainTypeOfTaskLoader = false
    }
}