import {debounce} from "lodash";

export default {
    data() {
        return {
            operators: [],
            customer: {
                customer_id: '',
                type_client: '',
                addressing_customers: '',
                company: '',
                surname: '',
                name: '',
                accommodation_street: '',
                accommodation_house_number: '',
                accommodation_postcode: '',
                accommodation_city: '',
                private_phone: '',
                personal_phone: '',
                email: '',
                email_private_password: '',
                email_private: '',
                telegram_id: '',
                date_of_birth: '',
                recommender: '',
                counters: []
            },
            counters: {
                counter_id: '',
                counters_type: 'Type',
                counters_number: '',
                counter_street: '',
                counter_house_number: '',
                counter_postcode: '',
                counter_city: '',
                iban: '',
                bic: '',
                banks_name: '',
                account_owner: '',
                mao_id: ''
            },
            contract: {
                contract_id: '',
                counters_id: '',
                operator_name: '',
                customer_number: '',
                prior_year_consumption: '',
                contract_start_date: '',
                contract_end_date: '',
                start_count: '',
                end_count: '',
                start_count_night: '',
                end_count_night: '',
                condition_number: '',
                calorific_value: '',
                consumption: '',
                consumption_night: '',
                contract_status: 'Pending',
            },
            customer_id: this.$route.params.id,
            customer_data: {
                client: {
                    date_of_birth: null
                },
                counters: [],
                contracts: []
            },
            searchCounters: false,
            isLoadingCounters: false,
            checkHeightCounters: false,
            countNextPageCounters: 1,
            countSearchNextPageCounters: 2,
            searchInputsCounters: '',
            countNextPageCountersPopup: 1,
            checkHeightCountersPopup: false,
            isLoadingCountersPopup: false,
            searchCountersPopup: '',
            countNextPageContracts: 2,
            checkHeightContracts: false,
            isLoadingContracts: false,
            searchContracts: false
        }
    },
    methods: {
        async searchOperatorsInDropdown(event) {
            this.operators = []
            if (event.length) {
                let response = await this.$services.searchOperatorsInDropdown.callMethod({
                    page: 1,
                    search: event
                })
                let result = await response.json()
                for (const operator in result) {
                    this.operators.push(result[operator].operator_name)
                }
            } else {
                await this.getOperatorsInDropdown()
            }
        },
        async getOperatorsInDropdown() {
            this.operators = []
            let response = await this.$services.getOperatorsInDropdown.callMethod()
            const result = await response.json()
            for (const oper in result) {
                this.operators.push(result[oper].operator_name)
            }
            return this.operators
        },
        async getCustomer() {
            let response = await this.$services.getCustomer.callMethod({id: this.customer_id})
            this.customer_data.client = await response.json()
        },
        async getCounters(page = 1) {
            const response = await this.$services.getCounters.callMethod({customer_id: this.customer_id, page})
            return await response.json()
        },
        async getContracts(counters, page = 1) {
            const response = await this.$services.getContracts.callMethod({counters_id: counters, page})
            return await response.json()
        },
        async searchInputCounters(event) {
            this.searchCounters = true
            this.countNextPageCounters = 1
            this.countSearchNextPageCounters = 2
            this.checkHeightCounters = false
            const $event = event
            this.searchInputsCounters = $event
            if (!$event.length) {
                this.customer_data.counters = []
                await this.getPaginationCounters()
            } else {
                let response = await this.$services.searchCounters.callMethod({
                    search: $event,
                    page: 1,
                    customer_id: this.$route.params.id
                })
                this.customer_data.counters = await response.json()
            }
        },
        async searchPaginationInputCounters() {
            try {
                let response = await this.$services.searchCounters.callMethod({
                    search: this.searchInputsCounters,
                    page: this.countSearchNextPageCounters,
                    customer_id: this.$route.params.id
                })
                let result = await response.json()
                this.customer_data.counters = [...this.customer_data.counters, ...result]
                this.countSearchNextPageCounters++
            } catch (e) {
                this.isLoadingCounters = false
                this.checkHeightCounters = true
            }
        },
        async getPaginationCounters() {
            this.searchCounters = false
            try {
                let result = await this.getCounters(this.countNextPageCounters)
                this.customer_data.counters = [...this.customer_data.counters, ...result]
                if (this.isLoadingCountersPopup) {
                    this.editOpenCustomer(this.customer_data)
                }
                this.countNextPageCounters++
            } catch (e) {
                this.isLoadingCounters = false
                this.checkHeightCounters = true
                this.isLoadingCountersPopup = false
                this.checkHeightCountersPopup = true
            }
        },
        editContract() {
            for (const contract_counter in this.counters.contracts[this.contract.index]) {
                for (const contract in this.contract) {
                    if (this.counters.contracts[this.contract.index][contract_counter] !== this.contract[contract] && (contract_counter === contract || contract === `'doc_'${contract_counter}`)) {
                        this.counters.contracts[this.contract.index][contract_counter] = this.contract[contract]
                    }
                }
            }
            this.closePopup()
        },
        editCounter() {
            for (const customer in this.customer.counters[this.counters.index]) {
                for (const counter in this.counters) {
                    if (this.customer.counters[this.counters.index][customer] !== this.counters[counter] && customer === counter) {
                        this.customer.counters[this.counters.index][customer] = this.counters[counter]
                    }
                    this.customer.counters[this.counters.index]['contracts'] = this.counters["contracts"]
                }
            }
            this.closePopup()
        },
        addContractInCounter() {
            this.contract.counters_id = this.counters.counters_id
            if (!this.counters.contracts) {
                this.counters.contracts = []
            }
            this.counters.contracts.push(this.contract)
            if (this.customer_data.contracts) {
                this.customer_data.contracts.push(this.contract)
            }
            this.contract = {
                contract_id: '',
                counters_id: '',
                operator_name: '',
                customer_number: '',
                prior_year_consumption: '',
                contract_start_date: '',
                contract_end_date: '',
                start_count: '',
                end_count: '',
                start_count_night: '',
                end_count_night: '',
                condition_number: '',
                calorific_value: '',
                consumption: '',
                consumption_night: '',
                contract_status: 'Pending',
            }
            this.closePopup()
        },
        addCounter() {
            if (this.$v.counters.$invalid) {
                this.$refs.createCustomer.callValidation('counters')
                return
            } else {
                this.customer.counters.push(this.counters)
                this.counters = {
                    counter_id: '',
                    counters_type: 'Type',
                    counters_number: '',
                    counter_street: '',
                    counter_house_number: '',
                    counter_postcode: '',
                    counter_city: '',
                    iban: '',
                    bic: '',
                    banks_name: '',
                    account_owner: '',
                    mao_id: ''
                }
                this.closePopup()
            }
        },
        closeAllPopup() {
            this.isPopupCustomerContracts = false
            this.isPopupCustomerEditContracts = false
            this.isPopupCustomerCounters = false
            this.isPopupCustomerEditCounters = false
            this.isPopupCustomer = false
            this.isPopupEditCustomer = false
        },
        async editAllCustomer() {
            if (this.isPopupCustomerContracts) {
                this.contract.counters_id = this.counters.counters_id
                if (!this.counters.contracts) {
                    this.counters.contracts = []
                }
                this.counters.contracts.push(this.contract)
                if (this.customer_data.contracts) {
                    this.customer_data.contracts.push(this.contract)
                }
                this.contract = {
                    contract_id: '',
                    counters_id: '',
                    operator_name: '',
                    customer_number: '',
                    prior_year_consumption: '',
                    contract_start_date: '',
                    contract_end_date: '',
                    start_count: '',
                    end_count: '',
                    start_count_night: '',
                    end_count_night: '',
                    condition_number: '',
                    calorific_value: '',
                    consumption: '',
                    consumption_night: '',
                    contract_status: 'Pending',
                }
                this.isPopupCustomerContracts = false
                this.isPopupCustomerEditContracts = false
            }
            if (this.isPopupCustomerCounters) {
                this.customer.counters.push(this.counters)
                this.counters = {
                    counter_id: '',
                    counters_type: 'Type',
                    counters_number: '',
                    counter_street: '',
                    counter_house_number: '',
                    counter_postcode: '',
                    counter_city: '',
                    iban: '',
                    bic: '',
                    banks_name: '',
                    account_owner: '',
                    mao_id: ''
                }
                this.isPopupCustomerCounters = false
                this.isPopupCustomerEditCounters = false
            }
            await this.editCustomer()
        },
        async editCustomer() {
            if (this.$v.customer.$invalid) {
                this.$v.customer.$touch()
                return
            } else {
                const formData = new FormData()
                formData.append('customer', JSON.stringify(this.customer))
                try {
                    let response = await this.$services.editCustomer.callMethod(formData)
                    let result = await response.json()
                    if (result.status === 'error') {
                        this.showMessage(false, '[Error] Bearbeitung einer Kunde fehlgeschlagen')
                    } else {
                        this.showMessage(true, 'Der Kunde wurde erfolgreich bearbeitet')
                    }
                } catch (e) {
                    this.showMessage(false, '[Error] Bearbeitung einer Kunde fehlgeschlagen')
                } finally {
                    this.closePopup()
                }
                await this.getCustomer()
                this.customer_data.counters = await this.getCounters()
            }
        },
        async getPaginationContracts() {
            this.searchContracts = false
            const counter_id = this.$store.state.counterIdWhenShowPopup
            try {
                let result = await this.getContracts(counter_id, this.countNextPageContracts)
                this.counters.contracts = [...this.counters.contracts, ...result]
                this.countNextPageContracts++
            } catch (e) {
                this.isLoadingContracts = false
                this.checkHeightContracts = true
            }
        },
        handleScrollTableCounters(event) {
            this.handleScroll('', 'debounceGetCounters', event, this.checkHeightCountersPopup, this.searchCountersPopup, 'isLoadingCountersPopup')
        },
        handleScrollTableContracts(event) {
            this.handleScroll('', 'debounceGetPaginationContracts', event, this.checkHeightContracts, this.searchContracts, 'isLoadingContracts')
        },
    },
    async mounted() {
        await this.getCustomer()
        await this.getPaginationCounters()
    },
    created() {
        this.debounceGetCounters = debounce(this.getPaginationCounters, 200)
        this.debounceSearchCounters = debounce(this.searchPaginationInputCounters, 200)
        this.debounceGetPaginationContracts = debounce(this.getPaginationContracts, 200)
    }
}
