export default {
    methods: {
        validation(obj1, obj2) {
            let value = this[obj1][obj2];
            let dotCount = (value.match(/\./g) || []).length;

            if (value.length === 2 && dotCount === 0) {
                value += ".";
            } else if (value.length === 5 && dotCount === 1) {
                value += ".";
            } else if (value.length === 3 && dotCount === 1) {
                value = value.slice(0, 2);
            } else if (value.length === 6 && dotCount === 2) {
                value = value.slice(0, 5);
            } else if (value.length > 10) {
                value = value.slice(0, 10);
            } else if ((value.length === 3 || value.length === 6) && value.slice(-1) !== '.') {
                value = value.slice(0, -1) + '.' + value.slice(-1)
            }

            this[obj1][obj2] = value;
        },
        filterInput(event) {
            if (((event.metaKey || event.ctrlKey) && [67, 86, 88, 65].includes(event.keyCode)) ||
                (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 46 || event.key === '.' ||
                    event.keyCode === 37 || event.keyCode === 39)) {
                return true;
            } else if (event.keyCode === 46 || !/^[0-9]$/.test(event.key)) {
                event.preventDefault();
            }
        },
    }
}