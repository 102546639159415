export default function documentSizeFilter (value) {
    if (String(value).length <= 6) {
        const calc = value / 1024
        return calc.toFixed(2) + ' Kb'
    } else if (String(value).length > 6) {
        const calc = value / 1024 / 1024
        return calc.toFixed(2) + ' Mb'
    } else if (String(value).length > 6) {
        const calc = value / 1024 / 1024 / 1024
        return calc.toFixed(2) + ' Gb'
    }
    return value
}