import {debounce} from "lodash";

export default {
    data() {
        return {
            new_folder: {
                customer_id: this.$route.params.id,
                folder_name: ''
            },
            folders: [],
            countNextPageFolders: 1,
            searchFolders: false,
            isLoadingFolders: false,
            checkHeightFolders: false,
            countSearchNextPageFolders: 2,
            searchInputsFolders: ''
        }
    },
    methods: {
        async createFolder() {
            try {
                const response = await this.$services.createFolder.callMethod(this.new_folder)
                const result = await response.json()
                if (result.status === 'error') {
                    this.showMessage(false, `[Error] Erstellen eines Ordners fehlgeschlagen`)
                } else {
                    this.showMessage(true, `Der Ordner wurde erfolgreich erstellt`)
                }
            } catch (e) {
                this.showMessage(false, `[Error] Erstellen eines Ordners fehlgeschlagen`)
            } finally {
                this.isPopupFolder = false
                this.folders = await this.getFolders()
            }
        },
        async getFolders(page = 1) {
            const response = await this.$services.getFolders.callMethod({customer_id: this.customer_id, page: page})
            return await response.json()
        },
        onFolderClick(id, folder) {
            this.$router.push({
                name: 'documents_page',
                params: {id: id, folder: folder}
            })
        },
        async getPaginationFolders() {
            this.searchFolders = false
            try {
                let result = await this.getFolders(this.countNextPageFolders)
                this.folders = [...this.folders, ...result]
                this.countNextPageFolders++
            } catch (e) {
                this.isLoadingFolders = false
                this.checkHeightFolders = true
            }
        },
        async searchInputFolders(event) {
            this.searchFolders = true
            this.countNextPageFolders = 1
            this.countSearchNextPageFolders = 2
            this.checkHeightFolders = false
            const $event = event
            this.searchInputsFolders = $event
            if (!$event.length) {
                this.folders = []
                await this.getPaginationFolders()
            } else {
                let response = await this.$services.searchFolders.callMethod({search: $event, page: 1, customer_id: this.$route.params.id})
                this.folders = await response.json()
            }
        },
        async searchPaginationInputFolders() {
            try {
                let response = await this.$services.searchFolders.callMethod({
                    search: this.searchInputsFolders,
                    page: this.countSearchNextPageFolders,
                    customer_id: this.$route.params.id
                })
                let result = await response.json()
                this.folders = [...this.folders, ...result]
                this.countSearchNextPageFolders++
            } catch (e) {
                this.isLoadingFolders = false
                this.checkHeightFolders = true
            }
        },
    },
    async mounted() {
        await this.getPaginationFolders()
    },
    created() {
        this.debounceGetFolders = debounce(this.getPaginationFolders, 200)
        this.debounceSearchFolders = debounce(this.searchPaginationInputFolders, 200)
    }
}
