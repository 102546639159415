export default {
    methods: {
        addBillingAddress() {
            this.new_customers.address_billing.push({value: ''})
        },
        addWorkingAddressAndNumber() {
            this.new_customers.address_working.push({
                address: '',
                meterNumber: ''
            })
        },
        deleteInputs(index, data) {
            data.splice(index, 1)
        },
    }
}