<template>
  <div class="auth container">
    <div class="logo">
      <img src="@/assets/images/logo_icon.svg" alt="">
      nrgYcon
    </div>
    <div class="auth__content content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth_layout",
}
</script>

<style lang="stylus">

.auth
  .logo
    padding-top: 32px
    padding-left: 32px
    justify-content flex-start

  &__content
    position fixed
    top: 50%
    left: 50%
    transform translate(-50%, -50%)
    width: 500px
    height auto
    padding: 32px
    border 1px solid #c9c9c9

</style>