import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/user";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    counterIdWhenShowPopup: null,
    isSidebar: false,
    staticUrl: process.env.VUE_APP_STATIC_URL
  },
  getters: {
  },
  mutations: {
    setCounterId (state, data) {
      state.counterIdWhenShowPopup = data
    },
    setSidebar(state, data) {
      state.isSidebar = data
    }
  },
  actions: {
  },
  modules: {
    user
  },
})
