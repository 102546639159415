<template>
  <div>
    <div v-if="isPopup" @click="togglePopup" class="mask"></div>
    <transition name="popup">
      <div v-if="isPopup" class="popup">
        <button @click="togglePopup" class="popup__close-btn"></button>
        <h2>Neue Generation</h2>
        <div>
          <base-field-input label="Wählen Sie ein Dokument">
            <v-select
              placeholder="Wählen Sie ein Dokument"
              class="vSelect"
              v-model="typeDocument"
              :options="optionsDocuments"
              label="label"
              index="value"
              :reduce="option => option.value"
            ></v-select>
          </base-field-input>
          <base-field-input label="Contract ID">
            <v-select
              class="vSelect"
              :options="contracts"
              v-model="contract_id"
              label="customer_number"
              :reduce="option => option.id"
              placeholder="Contract ID"
            ></v-select>
          </base-field-input>
          <base-field-input v-if="contract_id !== ''" label="Email">
            <v-select
              class="vSelect"
              :options="emails_option"
              v-model="email"
              placeholder="Email"
            ></v-select>
          </base-field-input>
          <div v-if="typeDocument" style="background: #b6bed8; width: 300px; margin: 0 auto 16px; height: 1px;"></div>
          <div v-if="typeDocument === 'discount_plan_change'">
            <div>
              <base-field-input label="Geben Sie den Betrag ein">
                <input type="text" v-model="discount_plan_change.amount">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="discount_plan_change.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'change_bank_details'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="change_bank_details.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'change_the_different_postal_address'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="change_the_different_postal_address.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="die Postadresse soll wie folgt aussehen:">
                <input v-model="change_the_different_postal_address.postal_address" type="text"
                       placeholder="die Postadresse soll wie folgt aussehen:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'change_of_postal_address_when_the_pc_moves'">
            <div>
              <base-field-input label="ANLAGE">
                <input type="text" v-model="change_of_postal_address_when_the_pc_moves.attachment" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Der Briefkopf soll wie folgt aussehen:">
                <input v-model="change_of_postal_address_when_the_pc_moves.letterhead" type="text"
                       placeholder="Der Briefkopf soll wie folgt aussehen:">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="BESTÄTIGUNG UNTER DER FOLGENDEN ADRESSE:">
                <input v-model="change_of_postal_address_when_the_pc_moves.confirm_follow_address" type="text"
                       placeholder="Bestätigung unter der folgenden adresse:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'letterhead_change'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="letterhead_change.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="die Postadresse soll wie folgt aussehen:">
                <input v-model="letterhead_change.postal_address" type="text"
                       placeholder="die Postadresse soll wie folgt aussehen:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'company_data_change'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="company_data_change.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Unternehmensdaten">
                <input v-model="company_data_change.company_data" type="text" placeholder="Unternehmensdaten">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'termination'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="termination.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Vertrag kündigen">
                <v-select
                  class="vSelect"
                  placeholder="Vertrag kündigen"
                  :options="[{label: 'Bis zum Ablauf der Frist', value: 'on_time'}, {label: 'zum nächstmöglichen Zeitpunkt', value: 'soon_as_possible'}]"
                  label="label"
                  index="value"
                  :reduce="option => option.value"
                  v-model="termination.terminate_contract"
                ></v-select>
              </base-field-input>
            </div>
            <div v-if="termination.terminate_contract === 'on_time'">
              <base-field-input label="Vertrag kündigen">
                <input v-model="termination.data_contract" type="text">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'brief_letter'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="brief_letter.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Daten">
                <input v-model="brief_letter.data" type="text" placeholder="Daten">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'special_termination_due_to_changes_in_the_agb'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="special_termination_due_to_changes_in_the_agb.attachment" type="text"
                       placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Daten">
                <input v-model="special_termination_due_to_changes_in_the_agb.data" type="text" placeholder="Daten">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'special_termination_price_adjustment'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="special_termination_price_adjustment.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Vertrag kündigen">
                <v-select
                  class="vSelect"
                  placeholder="Vertrag kündigen"
                  :options="[{label: 'Bis zum Ablauf der Frist', value: 'on_time'}, {label: 'zum nächstmöglichen Zeitpunkt', value: 'soon_as_possible'}]"
                  label="label"
                  index="value"
                  :reduce="option => option.value"
                  v-model="special_termination_price_adjustment.terminate_contract"
                ></v-select>
              </base-field-input>
            </div>
            <div v-if="special_termination_price_adjustment.terminate_contract === 'on_time'">
              <base-field-input label="Vertrag kündigen">
                <input v-model="special_termination_price_adjustment.data_contract" type="text">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'move_customer'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="move_customer.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Art der Mitteilung">
                <v-select
                  class="vSelect"
                  placeholder="Vertrag kündigen"
                  :options="[{label: 'hiermit teile ich Ihnen mit, dass ich zum folgendem Datum umziechem werde', value: 'moving_next_day'}, {label: 'hiermit teile ich Ihnen mit, dass ich zum folgenden Datum umgezogen bin', value: 'moved_the_next_day'}]"
                  label="label"
                  index="value"
                  :reduce="option => option.value"
                  v-model="move_customer.terminate_contract"
                ></v-select>
              </base-field-input>
              <base-field-input v-model="move_customer.date" label="Datum">
                <input type="text">
              </base-field-input>
              <base-field-input v-model="move_customer.name" label="Name/Vorname/Firma">
                <input type="text">
              </base-field-input>
              <base-field-input v-model="move_customer.address" label="Adresse">
                <input type="text">
              </base-field-input>
              <base-field-input v-model="move_customer.counter_number" label="Zählernummer">
                <input type="text">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'documents_request'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="documents_request.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Name/Vorname/Firma">
                <input v-model="documents_request.name" type="text" placeholder="Name/Vorname/Firma">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Str. Hnr.">
                <input v-model="documents_request.street" type="text" placeholder="Str. Hnr.">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="PLZ, Ort">
                <input v-model="documents_request.postcode" type="text" placeholder="PLZ, Ort">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'template_termination_for_moving_out'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="template_termination_for_moving_out.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Aufgrund des Auszugs zum:">
                <input v-model="template_termination_for_moving_out.due_to_the_move" type="text"
                       placeholder="Aufgrund des Auszugs zum:">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Zählernummer:">
                <input v-model="template_termination_for_moving_out.meter_number" type="text"
                       placeholder="Zählernummer:">
              </base-field-input>
            </div>
            <div>
              <base-field-input v-model="template_termination_for_moving_out.meter_reading" label="Zählerstand:">
                <input type="text" placeholder="Zählerstand:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'revocation_of_termination'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="revocation_of_termination.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Widerruf der Kündigung vom:">
                <input v-model="revocation_of_termination.revocation_termination_from" type="text"
                       placeholder="Widerruf der Kündigung vom:">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Zählernummer:">
                <input v-model="revocation_of_termination.counters_number" type="text" placeholder="Zählernummer:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'revocation_energy_contract'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="revocation_energy_contract.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Widerruf des Energievertrages vom:">
                <input v-model="revocation_energy_contract.revocation_energy_contract_from" type="text"
                       placeholder="Widerruf des Energievertrages vom:">
              </base-field-input>
            </div>
          </div>
          <div v-if="typeDocument === 'counter_data'">
            <div>
              <base-field-input label="ANLAGE">
                <input v-model="counter_data.attachment" type="text" placeholder="Anlage">
              </base-field-input>
            </div>
          </div>
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="togglePopup" href="#">Abbrechen</a>
          <button @click.prevent="generateDocument" class="blue-button">
            <span></span>
            Dokument generieren
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
  name: "createGenerateDocuments",
  components: {BaseFieldInput, fieldErrorRequired, vSelect},
  data() {
    return {
      emails_option: [],
      email: null,
      contract_id: '',
      contracts: [],
      isPopup: false,
      typeDocument: null,
      optionsDocuments: [
        {
          label: 'ABSCHLAGSPLAN AENDERUNG',
          value: 'discount_plan_change'
        },
        {
          label: 'AENDERUNG BANKVERBINDUNG',
          value: 'change_bank_details'
        },
        {
          label: 'AENDERUNG DER ABWEICHENDEN POSTADRESSE',
          value: 'change_the_different_postal_address'
        },
        {
          label: 'AENDERUNG DER POSTADRESSE BEIM UMZUG DES VP',
          value: 'change_of_postal_address_when_the_pc_moves'
        },
        {
          label: 'BRIEFKOPF AENDERUNG',
          value: 'letterhead_change'
        },
        {
          label: 'FIRMENDATEN AENDERUNG',
          value: 'company_data_change'
        },
        {
          label: 'KUENDIGUNG',
          value: 'termination'
        },
        {
          label: 'KURZBRIEF',
          value: 'brief_letter'
        },
        {
          label: 'SONDERKUENDIGUNG AUFGRUND VON AGB AENDERUNG',
          value: 'special_termination_due_to_changes_in_the_agb'
        },
        {
          label: 'SONDERKUENDIGUNG PREISANPASSUNG',
          value: 'special_termination_price_adjustment'
        },
        {
          label: 'UMZUG KUNDE',
          value: 'move_customer'
        },
        {
          label: 'UNTERLAGEN ANFORDERUNG',
          value: 'documents_request'
        },
        {
          label: 'Vorlage Kuendigungwegen Auszug',
          value: 'template_termination_for_moving_out'
        },
        {
          label: 'WIDERRUF DER KUENDIGUNG',
          value: 'revocation_of_termination'
        },
        {
          label: 'WIDERRUF DES ENERGIEVERTRAGES',
          value: 'revocation_energy_contract'
        },
        {
          label: 'ZAEHLERDATEN',
          value: 'counter_data'
        },
      ],
      // ABSCHLAGSPLAN_AENDERUNG
      discount_plan_change: {
        attachment: '',
        amount: ''
      },
      // AENDERUNG_BANKVERBINDUNG
      change_bank_details: {
        attachment: '',
      },
      // AENDERUNG_DER_ABWEICHENDEN_POSTADRESSE
      change_the_different_postal_address: {
        attachment: '',
        postal_address: ''
      },
      // AENDERUNG_DER_POSTADRESSE_BEIM_UMZUG_DES_VP
      change_of_postal_address_when_the_pc_moves: {
        attachment: '',
        letterhead: '',
        confirm_follow_address: ''
      },
      // BRIEFKOPF_AENDERUNG
      letterhead_change: {
        attachment: '',
        postal_address: ''
      },
      // FIRMENDATEN_AENDERUNG
      company_data_change: {
        attachment: '',
        company_data: ''
      },
      // KUENDIGUNG
      termination: {
        attachment: '',
        terminate_contract: null,
        data_contract: ''
      },
      // KURZBRIEF
      brief_letter: {
        attachment: '',
        data: ''
      },
      // SONDERKUENDIGUNG_AUFGRUND_VON_AGB_AENDERUNG
      special_termination_due_to_changes_in_the_agb: {
        attachment: '',
        data: ''
      },
      // SONDERKUENDIGUNG_PREISANPASSUNG
      special_termination_price_adjustment: {
        attachment: '',
        terminate_contract: null,
        data_contract: ''
      },
      // UMZUG_KUNDE
      move_customer: {
        attachment: '',
        terminate_contract: '',
        date: '',
        name: '',
        address: '',
        counter_number: ''
      },
      // UNTERLAGEN_ANFORDERUNG
      documents_request: {
        attachment: '',
        name: '',
        street: '',
        postcode: ''
      },
      // VorlageKuendigungwegenAuszug
      template_termination_for_moving_out: {
        attachment: '',
        due_to_the_move: '',
        meter_number: '',
        meter_reading: ''
      },
      // WIDERRUF_DER_KUENDIGUNG
      revocation_of_termination: {
        attachment: '',
        revocation_termination_from: '',
        counters_number: ''
      },
      // WIDERRUF_DES_ENERGIEVERTRAGES
      revocation_energy_contract: {
        attachment: '',
        revocation_energy_contract_from: ''
      },
      // ZAEHLERDATEN
      counter_data: {
        attachment: ''
      }
    }
  },
  methods: {
    async generateDocument() {
      try {
        const data = this.$data[this.typeDocument]
        data.id = this.$route.params.id
        data.contract_id = this.contract_id
        data.document = this.typeDocument
        data.email = this.email
        const response = await this.$services.documentGeneration.callMethod(data)
        const result = await response.json()
        this.$emit('getDocumentsGeneration')
        if (result.status === 'error') {
          this.$emit('generateDocumentError')
        } else {
          this.$emit('generateDocumentSuccess')
        }
      } catch (e) {
        this.$emit('generateDocumentError')
      } finally {
        await this.togglePopup()
      }
    },
    async togglePopup() {
      this.isPopup = !this.isPopup
      if (this.isPopup) {
        await this.getContracts()
      }
      this.contract_id = ''
      this.email = ''
      this.emails_option = []
    },
    async getContracts() {
      const response = await this.$services.getContractsInGenerateDocument.callMethod({'client_id': this.$route.params.id})
      this.contracts = await response.json()
    }
  },
  async created() {
    await this.getContracts()
  },
  watch: {
    async contract_id(to) {
      if (to !== '') {
        this.emails_option = []
        this.email = ''
        const response = await this.$services.getOperatorsInContract.callMethod({contract_id: to})
        const result = await response.json()
        for (const email in result) {
          this.emails_option.push(result[email])
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">

</style>