<template>
  <div class="general-class-customer">
    <div @click="$emit('closePopup')" v-if="isPopupCustomer"
         :class="{'show-counter': isPopupCustomerCounters, 'show-contracts': isPopupCustomerContracts}"
         class="mask"></div>
    <transition name="popup">
      <section v-if="isPopupCustomer" class="create-customer">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0"
                  v-if="isPopupCustomer && !isPopupCustomerContracts && !isPopupCustomerCounters"
                  @click.prevent="$emit('createAll')"
                  class="blue-button fixed-button">
            Vollständig beibehalten
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer && !isPopupCustomerContracts && !isPopupCustomerCounters"
                  @click="$emit('closeAllPopup')" style="right: 190px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Völlig aus
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <h4 v-if="!isPopupEditCustomer">Kunde anlegen</h4>
          <h4 v-if="isPopupEditCustomer">Kunde bearbeiten</h4>
          <span v-if="isPopupEditCustomer"></span>
          <p v-if="isPopupEditCustomer">#{{ customer.customer_id }}</p>
        </div>
        <div class="create-customer__navbar">
          <div style="display: flex">
            <a
              @click.prevent="isActive = 'address'"
              :class="{
                'create-customer__navbar-link-active': isActive === 'address',
                'create-customer__navbar-link-invalid': ($v.customer.type_client.$dirty && !$v.customer.type_client.required) ||
                                                        ($v.customer.addressing_customers.$dirty && !$v.customer.addressing_customers.required) ||
                                                        ($v.customer.name.$dirty && !$v.customer.name.required) ||
                                                        ($v.customer.surname.$dirty && !$v.customer.surname.required) ||
                                                        ($v.customer.accommodation_street.$dirty && !$v.customer.accommodation_street.required) ||
                                                        ($v.customer.accommodation_house_number.$dirty && !$v.customer.accommodation_house_number.required) ||
                                                        ($v.customer.accommodation_postcode.$dirty && !$v.customer.accommodation_postcode.required) ||
                                                        ($v.customer.accommodation_city.$dirty && !$v.customer.accommodation_city.required)
              }"
              href="#">
              Adresse
            </a>
            <a
              @click.prevent="isActive = 'contact'"
              :class="{
                'create-customer__navbar-link-active': isActive === 'contact',
                'create-customer__navbar-link-invalid': ($v.customer.personal_phone.$dirty && !$v.customer.personal_phone.required) ||
                                                        ($v.customer.email.$dirty && !$v.customer.email.required) ||
                                                        ($v.customer.date_of_birth.$dirty && !$v.customer.date_of_birth.required)
              }"
              href="#">
              Kontakt
            </a>
            <a
              @click.prevent="isActive = 'counter'"
              :class="{'create-customer__navbar-link-active': isActive === 'counter'}"
              href="#">
              Zähler
            </a>
          </div>
          <a v-if="isActive === 'counter'" @click.prevent="$emit('showCounters')"
             class="create-customer__navbar-new-counter" href="#"><span></span> Neuen Zähler hinzufügen</a>
        </div>
        <form class="create-customer__form">
          <transition name="popup">
            <div v-if="isActive === 'address'" class="create-customer__block create-customer__address">
              <div class="create-customer__block-group-inputs">
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="KUNDENTYP">
                      <v-select
                        placeholder="Kundentyp"
                        class="vSelect"
                        v-model="customer.type_client"
                        :options="['Privat', 'Firma']"
                      ></v-select>
                      <field-error-required
                        v-if="($v.customer.type_client.$dirty && !$v.customer.type_client.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="ANREDE">
                      <v-select
                        placeholder="Anrede"
                        class="vSelect"
                        v-model="customer.addressing_customers"
                        :options="['Herr', 'Frau', 'Firma', 'Familie']"
                      ></v-select>
                      <field-error-required
                        v-if="($v.customer.addressing_customers.$dirty && !$v.customer.addressing_customers.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div v-if="customer.type_client !== 'Privat'">
                  <base-field-input label="FIRMA">
                    <input v-model="customer.company" type="text" placeholder="Firma">
                  </base-field-input>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="VORNAME">
                      <input v-model="customer.name" type="text" placeholder="Vorname">
                      <field-error-required
                        v-if="($v.customer.name.$dirty && !$v.customer.name.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="NAME">
                      <input v-model="customer.surname" type="text" placeholder="Name">
                      <field-error-required
                        v-if="($v.customer.surname.$dirty && !$v.customer.surname.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_65_35">
                  <div>
                    <base-field-input label="Straße">
                      <input v-model="customer.accommodation_street" type="text"
                             placeholder="Straße">
                      <field-error-required
                        v-if="($v.customer.accommodation_street.$dirty && !$v.customer.accommodation_street.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="HNr.">
                      <input v-model="customer.accommodation_house_number" type="text"
                             placeholder="HNr.">
                      <field-error-required
                        v-if="($v.customer.accommodation_house_number.$dirty && !$v.customer.accommodation_house_number.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_35_65">
                  <div>
                    <base-field-input label="PLZ">
                      <input v-model="customer.accommodation_postcode" type="text"
                             placeholder="PLZ">
                      <field-error-required
                        v-if="($v.customer.accommodation_postcode.$dirty && !$v.customer.accommodation_postcode.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="Ort">
                      <input v-model="customer.accommodation_city" type="text" placeholder="Ort">
                      <field-error-required
                        v-if="($v.customer.accommodation_city.$dirty && !$v.customer.accommodation_city.required)"/>
                    </base-field-input>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isActive === 'contact'" class="create-customer__block create-customer__address">
              <div class="create-customer__block-group-inputs">
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="MOBIL">
                      <input v-model="customer.personal_phone" type="text" placeholder="Mobil">
                      <field-error-required
                        v-if="($v.customer.personal_phone.$dirty && !$v.customer.personal_phone.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="TELEFON PRIVAT">
                      <input v-model="customer.private_phone" type="text"
                             placeholder="Telefon Privat">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="EMAIL">
                      <input v-model="customer.email" type="text" placeholder="Email">
                      <field-error-required
                        v-if="($v.customer.email.$dirty && !$v.customer.email.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="TELEGRAM ID">
                      <input class="disabled-input" disabled v-model="customer.telegram_id" type="text"
                             placeholder="Telegram ID">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="EMAIL PRIVAT">
                      <input v-model="customer.email_private" type="text"
                             placeholder="Email privat">
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="EMAIL PRIVAT PASSWORT">
                      <input v-model="customer.email_private_password" type="text"
                             placeholder="Email privat passwort">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_65_35">
                  <div>
                    <base-field-input label="NAME">
                      <input disabled class="disabled-input" v-model="fullName" type="text"
                             placeholder="Name">
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="GEBURTSDATUM">
                      <input
                        type="text"
                        v-model="customer.date_of_birth"
                        @input="validation('customer', 'date_of_birth')"
                        placeholder="DD.MM.YYYY"
                        @keydown="filterInput"
                      >
                      <field-error-required
                        v-if="($v.customer.date_of_birth.$dirty && !$v.customer.date_of_birth.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div>
                  <base-field-input label="EMPFEHLUNGSGEBER">
                    <input v-model="customer.recommender" type="text"
                           placeholder="Empfehlungsgeber">
                  </base-field-input>
                </div>
              </div>
            </div>
            <div v-else-if="isActive === 'counter'" ref="scrollPopupCounters"
                 @scroll="handleScrollTableCounters"
                 class="table create-customer__table">
              <table v-if="customer.counters.length !== 0">
                <thead>
                <tr>
                  <td>Status</td>
                  <td>Zähler</td>
                  <td>address</td>
                  <td>IBAN</td>
                  <td>Name der Bank</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(counter, index) in customer.counters" :key="index + '_counter'">
                  <td class="create-customer__td-status"><span
                    :class="{'doc-active': counter.status === 'Active', 'doc-close': counter.status === 'Closed'}"></span>
                  </td>
                  <td class="create-customer__td-counter">
                    <div>
                      <a href="#"
                         @click.prevent="$emit('showEditCounter', {index: index, counter: counter, editTable: true})">#{{
                          counter.counters_number
                        }}</a>
                      <p>{{ counter.counters_type }}</p>
                    </div>
                  </td>
                  <td class="create-customer__td-address">
                    {{ counter.counter_street }} {{ counter.counter_house_number }}, <br>
                    {{ counter.counter_postcode }} {{ counter.counter_city }}
                  </td>
                  <td>
                    {{ counter.iban }}
                  </td>
                  <td>
                    {{ counter.banks_name }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
              <div v-if="isLoadingCountersPopup" class="loader"><span></span></div>
            </div>
          </transition>
          <div v-if="isActive !== 'counter'" class="create-customer__block-group-buttons">
            <button v-if="isPopupEditCustomer" @click.prevent="$emit('editCustomer')" class="blue-button">
              Bearbeiten Kunde
            </button>
            <button v-else @click.prevent="$emit('createCustomer')" class="blue-button">Create Customer
            </button>
            <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          </div>
        </form>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isPopupCustomerCounters" class="create-counter">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0" v-if="isPopupCustomer && !isPopupCustomerContracts"
                  @click.prevent="$emit('createAll')"
                  class="blue-button fixed-button">
            Vollständig beibehalten
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer && !isPopupCustomerContracts" @click="$emit('closeAllPopup')"
                  style="right: 190px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Völlig aus
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <h4 v-if="!isPopupEditCustomer">Zähler erstellen</h4>
          <h4 v-if="isPopupEditCustomer">Zähler bearbeiten</h4>
          <span v-if="isPopupEditCustomer"></span>
          <p v-if="isPopupEditCustomer">#{{ customer.customer_id }}</p>
        </div>
        <div class="create-customer__navbar">
          <div style="display: flex">
            <a
              @click.prevent="isActiveCounter = 'Counter Data'"
              :class="{'create-customer__navbar-link-active': isActiveCounter === 'Counter Data'}"
              href="#">
              Zählerdaten
            </a>
            <a
              @click.prevent="isActiveCounter = 'Contracts'"
              :class="{'create-customer__navbar-link-active': isActiveCounter === 'Contracts'}"
              href="#">
              Verträge
            </a>
          </div>
          <a @click.prevent="$emit('showContracts')" v-if="isActiveCounter === 'Contracts'"
             class="create-customer__navbar-new-counter" href="#">
            <span></span>Neuen Vertrag hinzufügen
          </a>
        </div>
        <form class="create-counter__form">
          <div style="width: 100%; height: 100%;">
            <div v-if="isActiveCounter === 'Counter Data'" class="create-counter__block">
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Zählertyp"
                                    :src_img="require(`@/assets/images/counter-type_${counters.counters_type.toLowerCase()}.svg`)">
                    <v-select
                      placeholder="Zählertyp"
                      class="vSelect"
                      v-model="counters.counters_type"
                      :options="['Strom', 'Gas', 'Strom RLM', 'Gas RLM']"
                    ></v-select>
                    <field-error-required
                      v-if="($v.counters.counters_type.$dirty && !$v.counters.counters_type.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Zählernummer">
                    <input v-model="counters.counters_number" type="text"
                           placeholder="Zählernummer">
                    <field-error-required
                      v-if="($v.counters.counters_number.$dirty && !$v.counters.counters_number.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-counter__line"></div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="MALO-ID">
                    <input v-model="counters.mao_id" type="text" placeholder="Malo-id">
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div class="create-counter__line"></div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="Straße">
                    <input v-model="counters.counter_street"
                           @input="$v.counters.counter_street.$touch()" type="text"
                           placeholder="Straße">
                    <field-error-required
                      v-if="($v.counters.counter_street.$dirty && !$v.counters.counter_street.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="HNr.">
                    <input v-model="counters.counter_house_number" type="text" placeholder="HNr.">
                    <field-error-required
                      v-if="($v.counters.counter_house_number.$dirty && !$v.counters.counter_house_number.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_35_65">
                <div>
                  <base-field-input label="PLZ">
                    <input v-model="counters.counter_postcode" type="text" placeholder="PLZ">
                    <field-error-required
                      v-if="($v.counters.counter_postcode.$dirty && !$v.counters.counter_postcode.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Ort">
                    <input v-model="counters.counter_city" type="text" placeholder="Ort">
                    <field-error-required
                      v-if="($v.counters.counter_city.$dirty && !$v.counters.counter_city.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-counter__line"></div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="IBAN">
                    <input v-model="counters.iban" type="text" placeholder="Iban">
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="BIC">
                    <input v-model="counters.bic" type="text" placeholder="Bic">
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="BANK">
                    <input v-model="counters.banks_name" type="text" placeholder="Bank">
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div style="align-items: center" class="create-customer__block_65_35">
                <div>
                  <base-field-input label="KONTOINHABER">
                    <input :disabled="account_owner_disabled" v-model="accountOwner"
                           :class="{'disabled-input': account_owner_disabled}"
                           type="text" placeholder="Kontoinhaber">
                  </base-field-input>
                </div>
                <div class="create-counter__checkbox">
                  <div class="checkbox">
                    <input v-model="account_owner_disabled" id="accountOwnerDisabled"
                           type="checkbox">
                    <label for="accountOwnerDisabled"></label>
                  </div>
                  Wie der Kunde
                </div>
              </div>
            </div>
            <div v-else-if="isActiveCounter === 'Contracts'" class="table create-customer__table"
                 ref="scrollPopupContracts" @scroll="handleScrollTableContracts"
            >
              <table>
                <thead>
                <tr>
                  <td></td>
                  <td>Zähler</td>
                  <td>Kundennummer</td>
                  <td>Start/Aktualisierung</td>
                  <td>cons:</td>
                  <td>unterzeichnet</td>
                  <td>beendet</td>
                  <td>Energieversorger</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(contract, index) in counters.contracts" :key="index + '_doc'">
                  <td class="create-customer__td-status"><span
                    :class="{'doc-active': contract.contract_status === 'Active', 'doc-close': contract.contract_status === 'Closed'}"></span>
                  </td>
                  <td>
                    <a href="#"
                       @click.prevent="$emit('showEditContract', {index: index, contract: contract})">#{{
                        counters.counters_number
                      }}</a>
                  </td>
                  <td>
                    {{ contract.customer_number }}
                  </td>
                  <td class="create-customer__td-start_update">
                    <div>
                      <span class="start">{{ contract.start_count || '---' }}</span>
                      <span class="update">{{ contract.end_count || '---' }}</span>
                    </div>
                  </td>
                  <td>{{ contract.consumption }} kWh</td>
                  <td>{{ contract.contract_start_date }}</td>
                  <td>{{ contract.contract_end_date }}</td>
                  <td>{{ contract.operator_name }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="isLoadingContractsPopup" class="loader"><span></span></div>
            </div>
          </div>
          <div v-if="isActiveCounter !== 'Contracts'" class="create-customer__block-group-buttons">
            <button v-if="isPopupCustomerEditCounters" @click.prevent="$emit('editCounter')"
                    class="blue-button">
              Zähler bearbeiten
            </button>
            <button v-else @click.prevent="$emit('addCounter')" class="blue-button">Zähler erstellen
            </button>
            <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          </div>
        </form>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isPopupCustomerContracts" class="create-documents">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0" v-if="isPopupCustomer" @click.prevent="$emit('createAll')"
                  class="blue-button fixed-button">
            Vollständig beibehalten
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer" @click="$emit('closeAllPopup')" style="right: 190px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Völlig aus
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <h4>Kunde</h4>
        </div>
        <div style="display: flex; align-items: flex-start; justify-content: space-between">
          <div>
            <div class="create-customer__block_50_50">
              <div class="create-documents__field">
                <base-field-input label="KUNDENNUMMER">
                  <div style="display: flex; align-items: center">
                    <input
                      v-model="contract.customer_number"
                      type="text"
                      placeholder="Kundennummer"
                    >
                  </div>
                </base-field-input>
              </div>
              <div class="create-documents__field">
                <base-field-input label="OPERATOR">
                  <div style="display: flex; align-items: center">
                    <!--                    <CustomSelect-->
                    <!--                      v-model="contract.operator_name"-->
                    <!--                      :options="operators"-->
                    <!--                      class="select no-icon"-->
                    <!--                      @search="searchOperators"-->
                    <!--                      placeholder="Operator"-->
                    <!--                    />-->
                    <v-select
                      placeholder="Operator"
                      class="vSelect"
                      v-model="contract.operator_name"
                      :options="operators"
                    ></v-select>
                  </div>
                </base-field-input>
              </div>
            </div>
            <div class="create-customer__block_50_50">
              <div></div>
              <div>
                <base-field-input label="VORJGHRESVERBRAUCH">
                  <input v-model="contract.prior_year_consumption" type="text"
                         placeholder="Vorjahresverbrauch">
                </base-field-input>
              </div>
            </div>
            <div class="create-customer__block_50_50">
              <div>
                <base-field-input label="Lieferbeginn">
                  <input
                    type="text"
                    v-model="contract.contract_start_date"
                    @input="validation('contract', 'contract_start_date')"
                    placeholder="Date (DD.MM.YYYY)"
                    @keydown="filterInput"
                  >
                </base-field-input>
              </div>
              <div>
                <base-field-input label="Lieferende">
                  <input
                    type="text"
                    v-model="contract.contract_end_date"
                    @input="validation('contract', 'contract_end_date')"
                    placeholder="Date (DD.MM.YYYY)"
                    @keydown="filterInput"
                  >
                </base-field-input>
              </div>
            </div>
            <div class="create-customer__block_50_50">
              <div>
                <base-field-input
                  :label="(counters.counters_type === 'Strom' || counters.counters_type === 'Strom RLM') ? 'Anfangszählerstand HT': 'Anfangszählerstand'">
                  <input v-model="contract.start_count" type="text"
                         @keydown="filterInput"
                         placeholder="Anfangszählerstand">
                </base-field-input>
              </div>
              <div>
                <base-field-input
                  :label="(counters.counters_type === 'Strom' || counters.counters_type === 'Strom RLM') ? 'Endzählerstand HT': 'Endzählerstand'">
                  <input v-model="contract.end_count" type="text" placeholder="Endzählerstand"
                         @keydown="filterInput"
                  >
                </base-field-input>
              </div>
            </div>
            <div v-if="counters.counters_type === 'Strom' || counters.counters_type === 'Strom RLM'"
                 class="create-customer__block_50_50">
              <div>
                <base-field-input
                  label="Anfangszählerstand NT">
                  <input
                    v-model="contract.start_count_night"
                    type="text"
                    placeholder="Anfangszählerstand NT"
                    @keydown="filterInput"
                  >
                </base-field-input>
              </div>
              <div>
                <base-field-input
                  label="Endzählerstand NT">
                  <input
                    v-model="contract.end_count_night"
                    type="text"
                    placeholder="Endzählerstand NT"
                    @keydown="filterInput"
                  >
                </base-field-input>
              </div>
            </div>
            <div v-if="counters.counters_type === 'Gas' || counters.counters_type === 'Gas RLM'"
                 class="create-customer__block_50_50">
              <div>
                <base-field-input label="ZUSTANDSZAHL">
                  <input v-model="contract.condition_number" type="text"
                         :class="{'disabled-input': counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'}"
                         placeholder="Zustandszahl"
                         :disabled="counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'"
                         @keydown="filterInput"
                  >
                </base-field-input>
              </div>
              <div>
                <base-field-input label="BRENNWERT">
                  <input v-model="contract.calorific_value" type="text"
                         :class="{'disabled-input': counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'}"
                         placeholder="Brennwert"
                         :disabled="counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'"
                         @keydown="filterInput"
                  >
                </base-field-input>
              </div>
            </div>
            <div class="create-customer__block_50_50">
              <div class="create-documents__field">
                <base-field-input label="VERBRAUCH HT"
                                  :src_img="require(`@/assets/images/counter-consumption_${counters.counters_type.toLowerCase()}.svg`)">
                  <input v-model="consumptionTotal" type="text" class="disabled-input show-icon"
                         placeholder="----"
                         disabled>
                </base-field-input>
              </div>
              <div v-if="counters.counters_type === 'Strom' || counters.counters_type === 'Strom RLM'"
                   class="create-documents__field">
                <base-field-input label="VERBRAUCH NT"
                                  :src_img="require(`@/assets/images/counter-consumption_${counters.counters_type.toLowerCase()}.svg`)">
                  <input v-model="consumptionTotalNight" type="text" class="disabled-input show-icon"
                         placeholder="----"
                         disabled>
                </base-field-input>
              </div>
            </div>
            <div class="create-customer__block_50_50">
              <div class="create-documents__field">
                <base-field-input label="STATUS">
                  <div style="display: flex; align-items: center">
                    <v-select
                      class="vSelect"
                      v-model="contract.contract_status"
                      :options="statusContractOptions"
                      label="label"
                      index="index"
                      :reduce="option => option.index"
                      :class="{'active-select': contract.contract_status === 'Active', 'closed-select': contract.contract_status === 'Closed', 'opened-select': contract.contract_status === 'Pending'}"
                    ></v-select>
                  </div>
                </base-field-input>
              </div>
            </div>
          </div>
          <div class="create-documents__block-group-buttons">
            <button v-if="isPopupCustomerEditContracts" @click.prevent="$emit('editContractInCounter')"
                    class="blue-button">Vertrag bearbeiten
            </button>
            <button v-else @click.prevent="$emit('addContractInCounter')" class="blue-button">Vertrag
              erstellen
            </button>
            <a @click.prevent="$emit('closePopup')" href="#">Abbrechen</a>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import validations from "@/mixins/customers/validations";
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import CustomSelect from "@/components/fields/CustomSelect.vue";
import lazyLoadScrollTable from "@/mixins/lazyLoadScrollTable";
import {debounce} from "lodash";
import eventDate from "@/mixins/eventDate";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import eventInteger from "@/mixins/eventInteger";


export default {
  name: "createCustomer",
  mixins: [validations, lazyLoadScrollTable, eventDate, eventInteger],
  components: {vSelect, BaseFieldInput, Datepicker, BaseCheckbox, fieldErrorRequired, CustomSelect},
  data() {
    return {
      isActive: 'address',
      isActiveCounter: 'Counter Data',
      account_owner_disabled: true,
      statusContractOptions: [
        {
          label: 'Anhängig',
          index: 'Pending'
        },
        {
          label: 'Aktiver Status',
          index: 'Active'
        },
        {
          label: 'Abgeschlossener Status',
          index: 'Closed'
        },
      ],
    }
  },
  props: {
    checkHeightContractsPopup: Boolean,
    isLoadingContractsPopup: Boolean,
    searchContractsPopup: Boolean,
    countNextPageContractsPopup: Number,
    checkHeightCountersPopup: Boolean,
    isLoadingCountersPopup: Boolean,
    searchCountersPopup: Boolean,
    operators: Array,
    isPopupEditCustomer: Boolean,
    isPopupCustomerEditCounters: Boolean,
    isPopupCustomerEditContracts: Boolean,
    isPopupCustomer: Boolean,
    isPopupCustomerCounters: Boolean,
    isPopupCustomerContracts: Boolean,
    customer: {
      customer_id: String,
      type_client: String,
      addressing_customers: String,
      company: String,
      surname: String,
      name: String,
      accommodation_street: String,
      accommodation_house_number: String,
      accommodation_postcode: String,
      accommodation_city: String,
      private_phone: String,
      personal_phone: String,
      email: String,
      email_private_password: String,
      email_private: String,
      telegram_id: String,
      date_of_birth: String,
      recommender: String,
    },
    counters: {
      counter_id: String,
      counters_type: String,
      counters_number: String,
      counter_street: String,
      counter_house_number: String,
      counter_postcode: String,
      counter_city: String,
      iban: String,
      bic: String,
      banks_name: String,
      account_owner: String,
      mao_id: String
    },
    contract: {
      contract_id: String,
      counters_id: String,
      operator_name: String,
      customer_number: String,
      prior_year_consumption: String,
      contract_start_date: String,
      contract_end_date: String,
      start_count: String,
      end_count: String,
      start_count_night: String,
      end_count_night: String,
      condition_number: String,
      calorific_value: String,
      consumption: String,
      consumption_night: String,
      contract_status: String,
    }
  },
  computed: {
    accountOwner: {
      get() {
        if (this.account_owner_disabled && this.counters.account_owner === '') {
          this.counters.account_owner = `${this.customer.surname} ${this.customer.name}`
          return `${this.customer.surname} ${this.customer.name}`
        } else {
          return this.counters.account_owner
        }
      },
      set(string) {
        this.counters.account_owner = string
      }
    },
    consumptionTotal() {
      if (this.counters.counters_type === 'Strom' || this.counters.counters_type === 'Strom RLM') {
        const amount = Number(this.contract.end_count) - Number(this.contract.start_count)
        if (Number(amount) < 0) {
          this.contract.consumption = 0
        } else {
          this.contract.consumption = amount
        }
        return this.contract.consumption
      } else if (this.counters.counters_type === 'Gas' || this.counters.counters_type === 'Gas RLM') {
        let amount = (Number(this.contract.end_count) - Number(this.contract.start_count)) * Number(this.contract.condition_number) * Number(this.contract.calorific_value)
        if (Number(amount) < 0) {
          this.contract.consumption = 0
        } else {
          this.contract.consumption = amount
        }
        return this.contract.consumption
      }
    },
    consumptionTotalNight() {
      if (this.counters.counters_type === 'Strom' || this.counters.counters_type === 'Strom RLM') {
        const amount = Number(this.contract.end_count_night) - Number(this.contract.start_count_night)
        if (Number(amount) < 0) {
          this.contract.consumption_night = 0
        } else {
          this.contract.consumption_night = amount || 0
        }
        return this.contract.consumption_night
      }
    },
    fullName() {
      return this.customer.surname + ' ' + this.customer.name
    },
  },
  methods: {
    async getPaginationCountersPopup() {
      this.searchCountersPopup = false
      try {
        let result = await this.getCounters(this.countNextPageCountersPopup)
        this.counters = [...this.counters, ...result]
        this.countNextPageCountersPopup++
      } catch (e) {
        this.isLoadingCountersPopup = false
        this.checkHeightCountersPopup = true
      }
    },
    handleScrollTableCounters() {
      if (this.isPopupEditCustomer) {
        this.$emit('handleScrollTableCounters', this.$refs.scrollPopupCounters)
      }
    },
    handleScrollTableContracts() {
      if (this.isPopupEditCustomer) {
        this.$emit('handleScrollTableContracts', this.$refs.scrollPopupContracts)
      }
    },
    openSystemFile() {
      this.$refs.systemPopup.click()
    },
    callValidation(name) {
      this.$v[name].$touch()
    },
    searchOperators(event) {
      this.$emit('searchOperators', event)
    }
  },
  created() {
    this.debounceGetCountersPopup = debounce(this.getPaginationCountersPopup, 200)
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.create-customer
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1440px
  width: 100%
  height 90vh
  max-height 1024px
  background-color #F7F8FD
  z-index 100
  border-radius 4px 4px 0 4px
  padding: 44px 38px

  &__group-button
    max-width 1440px
    width: 100%
    margin: 0 auto
    position fixed
    left: 50%
    transform translateX(-50%)
    bottom: 6px
    display flex
    justify-content flex-end
    z-index 1000

    button + button
      margin-left: 16px

    button
      padding: 9px 24px !important

  &__table
    max-height 100%

  &__td
    &-status
      span
        margin-left 12px
        width: 16px
        height: 16px
        background #fdab3d
        border-radius 100%
        display block

    &-counter
      div
        display flex
        flex-direction column
        align-items flex-start

        span
          color $blue
          font-weight: 500

        p
          color #999999
          margin-top: 4px

    &-address
      line-height 1.3

    &-start_update
      div
        display flex
        flex-direction column
        align-items flex-start

        .start
          color #999999
          margin-bottom: 4px

  &__breadcrumb
    display flex
    align-items center
    font-size: 24px
    font-weight: 500
    margin-bottom: 40px

    h4
      color #999999

    span
      display block
      width: 12px
      height: 12px
      border-bottom 2px solid #999999
      border-right 2px solid #999999
      transform rotate(-45deg)
      margin: 0 16px

  &__navbar
    border-bottom 1px solid #B6BED8
    display flex
    justify-content space-between

    a
      font-size: 20px
      margin-left: 32px
      color #B6BED8
      padding-bottom: 13px
      transition 0.2s
      border-bottom 2px solid #F7F8FD

      &:first-child
        margin-left: 0

    &-new-counter
      font-size: 16px !important
      color $blue !important
      display flex
      align-items center

      span
        margin-right: 10px
        width: 11px
        height: 11px
        position relative

        &:after
        &:before
          content ''
          display block
          width: 11px
          height: 2px
          background $blue
          border-radius 8px
          position absolute
          top: 4px

        &:after
          transform rotate(-90deg)


    &-link-active
      border-bottom 2px solid $blue !important
      color $blue !important
      text-shadow 2px 2px 4px rgba(31, 85, 250, 0.3)
    &-link-invalid
      color #c33 !important
      text-shadow 2px 2px 4px rgba(255, 59, 59, 0.3) !important

  &__form
    display flex
    align-items flex-start
    padding-top: 40px
    height calc(100% - 104px)

  &__block

    &-group-buttons
      display flex
      flex-direction column
      align-items center
      max-width 172px
      width: 100%
      margin-left: 192px
      margin-top: 20px

      button
        display flex
        justify-content center
        width: 100%

        &:first-child
          margin-bottom: 18px

      a
        margin-top: 32px
        color #5A5A5A

    &-group-inputs
      width 540px

    &_50_50
      display flex
      align-items flex-start

      div
        width: 100%

      div + div
        margin-left: 16px

    &_65_35
      display flex
      align-items flex-start

      div
        width: 100%

      div + div
        width: 35%
        margin-left: 16px

    &_35_65
      display flex
      align-items flex-start

      & > div
        width: 25%

      & > div + div
        width: 100%
        margin-left: 16px


</style>

<style lang="stylus">


.create-counter
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1000px
  width: 100%
  height 90vh
  max-height 918px
  background-color #F7F8FD
  z-index 110
  border-radius 4px 4px 0 4px
  padding: 44px 38px

  &__checkbox
    display flex
    align-items center
    font-style italic
    font-size: 14px
    color #999999

    div
      width: 14px
      margin-right: 8px

  &__line
    width: 100%
    height: 1px
    background #B6BED8
    margin-bottom: 18px

  &__form
    margin-top: 40px
    display flex
    justify-content space-between
    height calc(100% - 144px)

    .create-customer__block-group-buttons
      margin-left: 0 !important

  &__block
    width: 580px
    height calc(90vh - 228px)
    overflow auto
</style>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"
.create-documents
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1000px
  width: 100%
  height 90vh
  max-height 700px
  background-color #F7F8FD
  z-index 116
  border-radius 4px 4px 0 4px
  padding: 36px 32px

  &__open-file
    text-align center
    margin-top: 24px
    display block
    border 1px solid $blue
    padding: 12px 16px
    border-radius 4px


  &__block-group-buttons
    display flex
    align-items center
    flex-direction column
    margin-top: 18px

    button
      padding: 14px 70px

    a
      color #5A5A5A
      margin-top: 32px

  &__upload-file
    img
      position absolute
      right 8px !important
      top: 43px !important
      left auto !important

    input
      cursor pointer !important
      border 1px solid #b6bed8 !important
      padding-right: 42px

    div
      position absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      z-index 1
      cursor pointer

  .create-customer__breadcrumb
    font-size: 16px

    span
      width: 8px
      height: 8px
</style>

<style lang="stylus">
.doc-active
  background #00C875 !important

.doc-close
  background #c33 !important

.general-class-customer
  .table
    td:first-child
      padding: 4px

  .show-counter
    z-index 102

  .show-contracts
    z-index 114

.fixed-button
  position fixed
  bottom: -36px
  right: 0
  z-index 1000
  padding: 10px 24px !important

</style>
