<template>
  <div
      @click="$emit('resetMessage')"
      class="message"
      :class="{'message__success': success, 'message__error': !success}"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "message",
  props: {
    message: String,
    success: Boolean
  }
}
</script>

<style lang="stylus">
.message
  width: 100%
  text-align center
  padding: 16px 0
  background #c9c9c9
  margin-bottom: 28px
  color white
  cursor pointer
  &__error
    background #CC3333
  &__success
    background #00C875

</style>