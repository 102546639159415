import {required} from "vuelidate/lib/validators";

export default {
    validations: {
        create_user: {
            username: {required},
            password: {required},
            last_name: {required},
            first_name: {required},
        },
        validations: {
            type_of_task: {required}
        },
    }
}