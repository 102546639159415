export default {
    data() {
        return {
            successMessage: false,
            errorMessage: false,
            isMessage: false,
            addMessage: ''
        }
    },
    methods: {
        showMessage(state, message) {
            this.addMessage = message
            if (state) {
                this.successMessage = true
                this.errorMessage = false
                this.isMessage = true
                setTimeout(() => {
                    this.resetMessage()
                }, 5000)
            } else if (!state) {
                this.successMessage = false
                this.errorMessage = true
                this.isMessage = true
                setTimeout(() => {
                    this.resetMessage()
                }, 5000)
            }
        },
        resetMessage() {
            this.successMessage = false
            this.errorMessage = false
            this.isMessage = false
            this.addMessage = ''
        }
    }
}
