export default {
  methods: {
    filterInput(event) {
      if (((event.metaKey || event.ctrlKey) && [67, 86, 88, 65].includes(event.keyCode)) ||
        (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 46 ||
          event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 90 || (event.key === '.' && this.$route.name !== 'telegram-bot_page'))) {
        return true;
      } else if (event.keyCode === 46 || !/^[0-9]$/.test(event.key)) {
        event.preventDefault();
      }
    }
  }
}