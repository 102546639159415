export default {
  methods: {
    transformStatus(status) {
      if (status === 'Opened') {
        return 'Geöffnet'
      } else if (status === 'Completed') {
        return 'Abgeschlossen'
      } else if (status === 'Cancelled') {
        return 'Abgesagt'
      }
    }
  }
}