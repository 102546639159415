import {handle} from "@/decorator";

export default {
    data() {
        return {
            activeStatus: null,
            editStatus: null,
        }
    },
    methods: {
        showSelectStatus(id, editStatus) {
            this.activeStatus = id
            editStatus = ''
        },
        @handle('Status successfully changed', true)
        async changeStatus(task_id, status, index) {
            if (this.tasks[index].task.status === status) {
                this.activeStatus = null
            }
            await this.$services.editStatusTask.callMethod({id: task_id, status: status})
            this.activeStatus = null
            this.tasks[index].task.status = status
        }
    }
}