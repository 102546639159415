export default {
    data() {
        return {
            allCheckbox: false
        }
    },
    methods: {
        toggleAllCheckbox(array, btn) {
            this.allCheckbox = !this.allCheckbox
            this[btn] = !!this.allCheckbox
            this[array].forEach((row) => {
                if (array === 'tasks') row.task.isChecked = this.allCheckbox
                else row.isChecked = this.allCheckbox
            })
        },
        toggleRowCheckbox(row, btn) {
            row.isChecked = !row.isChecked
            if (!this.allCheckbox) this[btn] = !!row.isChecked;
        },
    }
}