<template>
  <base_layout @searchInput="searchInput">
    <transition name="opacity">
      <message v-if="isMessage" @resetMessage="resetMessage" :success="successMessage"
               :message="addMessage"/>
    </transition>
    <div class="content">
      <div class="customer__title-and-buttons">
        <h1 class="title-page">#{{ customer_data.client.id }} {{ customer_data.client.surname }}
          {{ customer_data.client.name }}</h1>
      </div>
      <div class="customer__content">
        <div class="customer__left-content">
          <div style="position: relative" class="customer__table white-container">
            <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="loader big-loader"><span></span>
            </div>
            <div :class="{'fadein-animation': !mainLoader}" style="height: 100%;" v-else>
              <div class="customer__fixed">
                <div class="customer__table-title-and-buttons">
                  <h3>Aufgaben</h3>
                  <div class="group-btn">
                    <button v-if="user.user_type[0].user_type === 'admin'" @click.prevent="showPopup('isPopupTask')"
                            class="blue-button">
                      <span></span>Neue Aufgabe
                    </button>
                  </div>
                </div>
                <div style="border-bottom: 1px solid #B6BED8; padding-bottom: 8px" class="tasks__sort">
                  <a @click.prevent="clickFilter()" :class="{'tasks__sort-active': activeLinkFilter === null}"
                     href="#">Alle Aufgaben</a>
                  <a @click.prevent="clickFilter('Opened')"
                     :class="{'tasks__sort-active': activeLinkFilter === 'Opened'}"
                     href="#">Geöffnet</a>
                  <a @click.prevent="clickFilter('Completed')"
                     :class="{'tasks__sort-active': activeLinkFilter === 'Completed'}"
                     href="#">Abgeschlossen</a>
                  <a @click.prevent="clickFilter('Cancelled')"
                     :class="{'tasks__sort-active': activeLinkFilter === 'Cancelled'}"
                     href="#">Abgesagt</a>
                </div>
              </div>
              <div class="customer__wrap-table" ref="scrollContainer"
                   @scroll="handleScroll('debounceSearchTasks', 'debounceGetTasks')">
                <table v-if="tasks && tasks.length !== 0" cellspacing="0" cellpadding="0" class="table">
                  <thead>
                  <tr>
                    <td class="text-transform-uppercase">ID</td>
                    <td class="text-transform-uppercase">Erstellt</td>
                    <td class="text-transform-uppercase">Zähler</td>
                    <td class="text-transform-uppercase">Aufgabe</td>
                    <td class="text-transform-uppercase" v-if="user.user_type[0].user_type === 'admin'">Mitarbeiter</td>
                    <td class="text-transform-uppercase">Verbleibende Zeit</td>
                    <td class="text-transform-uppercase">Status</td>
                    <td class="text-transform-uppercase">Aktion</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(task, index) in tasks" :key="'task' + task.task.id">
                    <td class="table__column-id">
                      <a href="#" @click.prevent="editOpenTask(task)">#{{ task.task.id }}</a>
                    </td>
                    <td>{{ task.task.create_of_date | date('date') }}</td>
                    <td>{{ task.task.counter_number }}</td>
                    <td>{{ task.task.type_of_task }}</td>
                    <td v-if="user.user_type[0].user_type === 'admin'">
                      <div class="table__column-employee">
                  <span>{{
                      transformEmployee(task.user[0].first_name, task.user[0].last_name)
                    }}</span>
                      </div>
                    </td>
                    <td class="table__column-deadline">
                      <div
                        :class="{'table__column-deadline-red': moment(task.task.deadline, 'DD.MM.YYYY').isBefore(moment())}">
                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.7453 3.5094C4.01997 3.5094 1 6.52937 1 10.2547C1 13.98 4.01997 17 7.7453 17C11.4706 17 14.4906 13.98 14.4906 10.2547C14.4906 6.52937 11.4706 3.5094 7.7453 3.5094ZM7.7453 3.5094V1M7.7453 6.20752V10.2547L10.4434 11.6038"
                            stroke="#232323" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ task.task.deadline }}
                      </div>
                    </td>
                    <td class="table__column-status"
                        :class="{
                  'table__column-status-open': task.task.status === 'Opened',
                  'table__column-status-cancel': task.task.status === 'Cancelled',
                  'table__column-status-complete': task.task.status === 'Completed'
                }"
                    >
                      <div>
                        <span></span>
                        {{ transformStatus(task.task.status) }}
                      </div>
                    </td>
                    <td style="position: relative">
                      <a @click.prevent="deleteWindow = index" style="position: relative; z-index: 1;" href="#">
                        <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                      </a>
                      <div v-if="deleteWindow === index" class="warning-delete">
                        Sind Sie sicher, dass Sie den Client entfernen möchten?
                        <div>
                          <button @click.prevent="deleteTask(task.task.id)">Ja</button>
                          <button @click.prevent="deleteWindow = null">Nein</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
                <div v-if="isLoading" class="loader"><span></span></div>
              </div>
            </div>
          </div>
          <div class="customer__documents white-container">
            <div class="customer__table-title-and-buttons">
              <h3>Ordner</h3>
              <div class="group-btn">
                <button @click="showPopup('isPopupFolder')" class="blue-button">
                  <span></span> Neue Mappe
                </button>
              </div>
            </div>
            <div class="table fadein-animation customer__counters-table"
                 ref="scrollContainerFolders"
                 @scroll="handleScroll('debounceSearchFolders', 'debounceGetFolders', 'scrollContainerFolders', checkHeightFolders, searchFolders, 'isLoadingFolders')"
            >
              <table v-if="folders.length !== 0">
                <thead>
                <tr>
                  <td class="text-transform-uppercase">Ordner</td>
                  <td class="text-transform-uppercase">Aktion</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(folder, index) in folders" :key="'folder_' + folder.id">
                  <td class="table__column-folder-name">
                    <a href="#" @click.prevent="onFolderClick($route.params.id, `${folder.folder_name}_${folder.id}`)">
                      <img src="@/assets/images/folder_icon.svg" alt="">
                      {{ folder.folder_name }}
                    </a>
                  </td>
                  <td class="table__column-action" style="position: relative">
                    <a @click.prevent="deleteWindowFolder = index" style="position: relative; z-index: 1;" href="#">
                      <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                    </a>
                    <div v-if="deleteWindowFolder === index" class="warning-delete">
                      Sind Sie sicher, dass Sie den Client entfernen möchten?
                      <div>
                        <button @click.prevent="deleteFolder(folder.id)">Ja</button>
                        <button @click.prevent="deleteWindowFolder = null">Nein</button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
              <div v-if="isLoadingFolders" class="loader"><span></span></div>
            </div>
          </div>
          <div class="customer__documents white-container">
            <div class="customer__table-title-and-buttons">
              <h3>Zähler</h3>
              <div class="group-btn">
                <button @click.prevent="editOpenCounter({newTable: true})" class="blue-button">
                  <span></span> Neuer Zähler
                </button>
              </div>
            </div>
            <div class="table fadein-animation customer__counters-table"
                 ref="scrollContainerCounters"
                 @scroll="handleScroll('debounceSearchCounters', 'debounceGetCounters', 'scrollContainerCounters', checkHeightCounters, searchCounters, 'isLoadingCounters')"
            >
              <table v-if="customer_data.counters.length !== 0">
                <thead>
                <tr>
                  <td class="text-transform-uppercase">Status</td>
                  <td class="text-transform-uppercase">Zähler</td>
                  <td class="text-transform-uppercase">ADRESSE</td>
                  <td class="text-transform-uppercase">IBAN</td>
                  <td class="text-transform-uppercase">NAME BANK</td>
                  <td class="text-transform-uppercase">MALO-ID</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(counter, index) in customer_data.counters" :key="counter.counters_number">
                  <td class="create-customer__td-status"><span
                    :class="{'doc-active': counter.status === 'Active', 'doc-close': counter.status === 'Closed'}"></span>
                  </td>
                  <td class="create-customer__td-counter">
                    <div>
                      <span>#<a href="#"
                                @click.prevent="editOpenCounter({index: index, counter: counter, editTable: true})">{{
                          counter.counters_number
                        }}</a></span>
                      <p>{{ counter.counters_type }}</p>
                    </div>
                  </td>
                  <td class="create-customer__td-address">
                    {{ counter.counter_street }} {{ counter.counter_house_number }}, <br>
                    {{ counter.counter_postcode }} {{ counter.counter_city }}
                  </td>
                  <td>
                    {{ counter.iban }}
                  </td>
                  <td>
                    {{ counter.banks_name }}
                  </td>
                  <td>{{ counter.mao_id }}</td>
                </tr>
                </tbody>
              </table>
              <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
              <div v-if="isLoadingCounters" class="loader"><span></span></div>
            </div>
          </div>
          <div class="customer__documents white-container">
            <div class="customer__table-title-and-buttons">
              <h3>Erstellung von Dokumenten</h3>
              <div class="group-btn">
                <button @click.prevent="$refs.generateDocument.togglePopup()" class="blue-button">
                  <span></span> Dokument generieren
                </button>
              </div>
            </div>
            <div class="table fadein-animation customer__counters-table">
              <table>
                <thead>
                <tr style="padding-top: 16px;">
                  <td style="padding-left: 24px !important;" class="text-transform-uppercase">Name</td>
                  <td class="customer__documents-table-column-small text-transform-uppercase">Größe</td>
                  <td>Aktion</td>
                  <!--                  <td class="documents__td-action text-transform-uppercase">Aktion</td>-->
                </tr>
                </thead>
                <tbody>

                <tr v-for="(document, index) in documentsGeneration" :key="`documentGeneration_${index}`">
                  <td class="customer__documents-table-column-name documents__td-type-document">
                    <a target="_blank" :href="url + document.file">
                <span
                  class="documents__td-icon"
                  style="background: #ba2d2d"
                >pdf</span>
                      {{ transformNameDocument(document.file) }}
                    </a>
                  </td>
                  <td class="customer__documents-table-column-small">{{ document.size | size() }}</td>
                  <td style="position: relative" class="documents__td-action">
                    <a @click.prevent="deleteWindowDocument = index" style="position: relative; z-index: 1;" href="#">
                      <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                    </a>
                    <div v-if="deleteWindowDocument === index" class="warning-delete">
                      Sind Sie sicher, dass Sie den Client entfernen möchten?
                      <div>
                        <button @click.prevent="deleteGenerateDocument(document.id)">Ja</button>
                        <button @click.prevent="deleteWindowDocument = null">Nein</button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <create-task
            :user="user"
            :is-popup-task="isPopupTask"
            :is-popup-type-of-task="isPopupTypeOfTask"
            :is-popup-edit-task="isPopupEditTask"
            :counters-in-customer-computed="countersInCustomerComputed"
            :customers-for-dropdown="customersForDropdown"
            :new-task="newTask"
            :search-customers-field="searchCustomersField"
            :type_of_tasks="type_of_tasks"
            :users="users"
            @closePopup="closePopup"
            @searchCustomersInPopup="searchCustomersInPopup"
            @editIdNumberCustomer="editIdNumberCustomer"
            @getCountersInCustomer="getCountersInCustomer"
            @searchTypeOfTask="searchTypeOfTask"
            @searchCounters="searchCountersInTask"
            @editTask="editTask"
            @createTask="createTask"
            @showCreateTypeOfTask="isPopupTypeOfTask = true"
            :status-options="statusOptions"
            :user-options="userOptions"
          />
          <create-type-of-task
            :type_of_task="type_of_task"
            :is-popup-type-of-task="isPopupTypeOfTask"
            @closePopup="closePopupUserAndTypeTask"
            @actionTypeOfTask="createTypeOfTask"
          />
        </div>
        <div class="customer__right-content fadein-animation">
          <PersonalData
            :customer-data="customer_data"
            @editOpenCustomer="editOpenCustomer(customer_data)"
          />
        </div>
      </div>
    </div>
    <create-customer
      :check-height-contracts-popup="checkHeightContracts"
      :count-next-page-contracts-popup="countNextPageContracts"
      :is-loading-contracts-popup="isLoadingContracts"
      :search-contracts-popup="searchContracts"
      :check-height-counters-popup="checkHeightCountersPopup"
      :is-loading-counters-popup="isLoadingCountersPopup"
      :search-counters-popup="searchCountersPopup"
      :customer="customer"
      :contract="contract"
      :counters="counters"
      :operators="operators"
      :is-popup-edit-customer="isPopupEditCustomer"
      :is-popup-customer-edit-counters="isPopupCustomerEditCounters"
      :is-popup-customer-edit-contracts="isPopupCustomerEditContracts"
      :is-popup-customer="isPopupCustomer"
      :is-popup-customer-counters="isPopupCustomerCounters"
      :is-popup-customer-contracts="isPopupCustomerContracts"
      @showCounters="showPopup('isPopupCustomerCounters')"
      @closePopup="closePopup"
      @showContracts="showPopup('isPopupCustomerContracts')"
      @addCounter="addCounter"
      @addContractInCounter="addContractInCounter"
      @editCustomer="editCustomer"
      @showEditCounter="editOpenCounter"
      @showEditContract="editOpenContract"
      @editCounter="editCounter"
      @editContractInCounter="editContract"
      @searchOperators="searchOperatorsInDropdown"
      @handleScrollTableCounters="handleScrollTableCounters"
      @handleScrollTableContracts="handleScrollTableContracts"
      @createAll="editAllCustomer"
      @closeAllPopup="closeAllPopup"
      ref="createCustomer"
    />
    <create-folder
      :is-popup-folder="isPopupFolder"
      :new_folder="new_folder"
      @closePopup="closePopup"
      @createFolder="createFolder"
    />
    <create-generate-documents
      ref="generateDocument"
      @getDocumentsGeneration="getDocumentsGeneration"
      @generateDocumentSuccess="showMessage(true, `Dokument erfolgreich erstellt`)"
      @generateDocumentError="showMessage(false, `Dokument konnte nicht erstellt werden`)"
    />
  </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout";
import createCustomer from "@/components/popups/createCustomer.vue";
import moment from 'moment'

// PLUGINS
import Vue from "vue";
import {debounce} from "lodash";
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';

// COMPONENTS
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import message from "@/components/message";
import fieldErrorRequired from '@/components/fieldErrorRequired'
import CustomSelect from "@/components/fields/CustomSelect.vue";
import createFolder from "@/components/popups/createFolder.vue";

// MIXINS
import success_error_state from "@/mixins/success_error_state";
import changeStatus from "@/mixins/tasks/changeStatus";
import togglePopup from "@/mixins/togglePopup";
import transformEmployee from "@/mixins/transformEmployee";
import taskValidations from '@/mixins/tasks/validations'
import getUser from "@/mixins/getUser";
import lazyLoadScrollTable from '@/mixins/lazyLoadScrollTable'
import validations from "@/mixins/customers/validations";
import create from "@/mixins/tasks/create";
import actionFolders from "@/mixins/folders/action";
import actionCustomer from "@/mixins/customer/action";
import Delete from '@/mixins/tasks/delete'
import transformStatus from "@/mixins/transformStatus";

// DECORATORS
import PersonalData from "@/components/elements/PersonalData.vue";
import CreateTypeOfTask from "@/components/popups/createTypeOfTask.vue";
import CreateTask from "@/components/popups/createTask.vue";
import CreateGenerateDocuments from "@/components/popups/createGenerateDocuments.vue";

export default {
  name: "customer_page",
  components: {
    CreateGenerateDocuments,
    CreateTask,
    CreateTypeOfTask,
    PersonalData,
    Base_layout,
    Datepicker,
    BaseFieldInput,
    message,
    fieldErrorRequired,
    createCustomer,
    CustomSelect,
    createFolder
  },
  mixins: [transformStatus, Delete, create, changeStatus, togglePopup, transformEmployee, success_error_state, taskValidations, getUser, lazyLoadScrollTable, validations, actionFolders, actionCustomer],
  data() {
    return {
      url: window.location.origin,
      tasks: [],
      activeLinkFilter: null,
      isLoading: false,
      countNextPage: 1,
      searchInputs: '',
      countSearchNextPage: 2,
      mainLoader: true,
      users: [],
      moment: moment,
      deleteWindowFolder: null,
      documentsGeneration: [],
      deleteWindowDocument: null
    }
  },
  async created() {
    console.log(window.location)
    console.log(process.env)
    if (document.title.includes('id')) {
      document.title = document.title.replace(/id/g, this.$route.params.id);
    }
    this.newTask.customer_id = this.customer_id
    this.debounceGetTasks = debounce(this.checkUserTypeAndStatusInTasks, 200)
    this.debounceSearchTasks = debounce(this.searchPaginationInput, 200)
  },
  methods: {
    async deleteGenerateDocument(id) {
      try {
        const response = await this.$services.deleteGenerateDocument.callMethod({document_id: id})
        this.showMessage(true, 'Das Dokument wurde erfolgreich gelöscht')
      } catch (e) {
        this.showMessage(false, '[Error] Löschen des Dokuments fehlgeschlagen')
      } finally {
        this.deleteWindowDocument = null
        await this.getDocumentsGeneration()
      }

    },
    transformNameDocument(name) {
      return name.split('/')[2]
    },
    async getDocumentsGeneration() {
      const response = await this.$services.getDocumentsGeneration.callMethod({client_id: this.$route.params.id})
      this.documentsGeneration = await response.json()

    },
    async deleteFolder(id) {
      try {
        this.deleteWindowFolder = null
        await this.$services.deleteFolder.callMethod({folder_document_id: id})
        this.folders = await this.getFolders()
        this.showMessage(true, 'Der Ordner wurde erfolgreich gelöscht')
      } catch (e) {
        this.showMessage(false, '[Error] Der Ordner konnte nicht gelöscht werden, versuchen Sie es erneut!')
      }
    },
    async searchTypeOfTask(event) {
      if (event.length !== 0) {
        this.type_of_tasks = []
        const response = await this.$services.searchTypeOfTask.callMethod({search: event})
        const result = await response.json()
        for (const typeOfTask in result) {
          this.type_of_tasks.push(result[typeOfTask].type_of_tasks)
        }
      } else {
        await this.getTypeOfTask()
      }
    },
    async getTypeOfTask() {
      try {
        let response = await this.$services.getTypeOfTask.callMethod({page: 1})
        const result = await response.json()
        for (const typeOfTask in result) {
          this.type_of_tasks.push(result[typeOfTask].type_of_tasks)
        }
      } catch (e) {
      }
    },
    async searchPaginationInput() {
      let dataObj
      let response
      if (this.user.user_type[0].user_type === 'admin') {
        dataObj = {search: this.searchInputs, page: this.countSearchNextPage, customer_id: this.customer_id}
        response = await this.$services.searchTasks.callMethod(dataObj)
        if (response.status === 400) {
          this.isLoading = false
          this.checkHeight = true
        }
      } else {
        dataObj = {
          search: this.searchInputs,
          user_id: this.user.user.id,
          page: this.countSearchNextPage,
          customer_id: this.customer_id
        }
        response = await this.$services.searchTasks.callMethod(dataObj)
      }
      let result = await response.json()
      this.tasks = [...this.tasks, ...result]
      this.countSearchNextPage++

    },
    async searchInput() {
      const $event = event.target.value
      this.searchInputs = $event
      this.checkHeight = false
      this.isLoading = false
      this.search = true
      this.countSearchNextPage = 2
      let response
      let dataObj
      if (!$event.length) {
        this.clickFilter(this.activeLinkFilter)
      } else {
        if (this.user.user_type[0].user_type === 'admin') {
          dataObj = {search: $event, page: 1, customer_id: this.customer_id}
          response = await this.$services.searchTasks.callMethod(dataObj)
        } else {
          dataObj = {search: $event, user_id: this.user.user.id, page: 1, customer_id: this.customer_id}
          response = await this.$services.searchTasks.callMethod(dataObj)
        }
        this.tasks = await response.json()
      }
      this.searchInputFolders($event)
      this.searchInputCounters($event)
    },
    async clickFilter(filter = null) {
      this.search = false
      this.countNextPage = 2
      this.checkHeight = false
      this.isLoading = false
      let dataObj = {page: 1, customer_id: this.customer_id}
      this.activeLinkFilter = filter
      if (filter !== null) {
        dataObj.status = filter
      }
      if (this.user.user_type[0].user_type === 'admin') {
        this.tasks = await this.getTasks(dataObj)
      } else {
        dataObj.user_id = this.user.user.id
        this.tasks = await this.getTasks(dataObj)
      }
    },
    async checkUserTypeAndStatusInTasks() {
      let result
      let dataObj = {
        page: this.countNextPage,
        customer_id: this.customer_id
      }
      if (this.activeLinkFilter !== null) {
        dataObj.status = this.activeLinkFilter
      }

      if (this.user.user_type[0].user_type === 'admin') {
        result = await this.getTasks(dataObj)

        if (result !== undefined) {
          this.tasks = [...this.tasks, ...result]
        }
      } else {
        dataObj.user_id = this.user.user.id
        result = await this.getTasks(dataObj)

        if (result !== undefined) {
          this.tasks = [...this.tasks, ...result]
        }
      }
      this.countNextPage++

      this.addIsCheckedBool()
      this.isLoading = false
    },
    addIsCheckedBool() {
      this.tasks.forEach((task) => {
        Vue.set(task.task, 'isChecked', false)
      })
    },
    async getTasks(obj) {
      try {
        let response = await this.$services.getTasks.callMethod(obj)
        return await response.json()
      } catch (e) {
        this.isLoading = false
        this.checkHeight = true
        console.warn('[WARN]: Die Aufgaben sind vorbei!')
      }
    },
  },
  async mounted() {
    let response = await this.$services.getUsers.callMethod()
    this.users = await response.json()
    await this.checkUserTypeAndStatusInTasks()
    await this.getTypeOfTask()
    await this.getDocumentsGeneration()
    this.mainLoader = false
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.customer
  &__title-and-buttons
    display flex
    justify-content space-between
    align-items center

  &__counters
    &-table
      max-height 40vh

  &__content
    display flex
    align-items flex-start
    width: 100%
    margin-top: 42px

  &__fixed
    margin-bottom: 20px

  &__wrap-table
    height: calc(100% - 100px)
    overflow-y scroll

    &::-webkit-scrollbar
      display none

  &__left-content
    width: 100%

  &__right-content
    min-width: 325px
    margin-left: 36px

  &__table
    height: 40vh
    min-height 500px

    &-title-and-buttons
      height: 46px
      display flex
      justify-content space-between
      align-items center
      margin-bottom: 16px

  &__counters
    &-table
      td
        ul
          li
            color $black
            font-weight: 400
            margin-bottom: 8px

      &-column
        &-name
          vertical-align baseline

          span
            transform rotate(-90deg)
            margin-right: 9px

          &-show
            span
              transform rotate(0)

  &__documents
    margin-top: 40px
    overflow-y scroll

    h3
      margin-bottom: 20px

    &-table
      width: 100%

      &-column
        &-name
          cursor: pointer

          div
            display flex
            align-items center
            color $black
            font-weight: 500

            img
              margin-right: 16px

        &-small
          min-width 130px
          width: 15%

      td
        color #999999

        &:first-child
          padding-left: 16px

        &:last-child
          padding-right: 16px

      table
        width: 100%
        border 1px solid #E6E6E6
        padding: 18px 32px
        border-collapse: collapse;

        thead
          td
            font-weight: 500
            padding-bottom: 2px
            padding-top: 16px

        tbody
          td
            padding: 16px 4px
            border-bottom 1px solid #E6E6E6

          tr
            &:last-child
              td
                padding-bottom: 16px
                border-bottom: 0

    &::-webkit-scrollbar
      display none

  &__data-customer
    padding: 0 !important

    &-section
      padding: 32px
      border-bottom: 1px solid #EBEBEB

      &:last-child
        border-bottom none

      p
        margin-bottom: 8px
        font-size: 16px
        overflow-wrap break-word

        &:last-child
          margin-bottom: 0

    &-title-and-edit
      display flex
      justify-content space-between
      align-items center
      margin-bottom: 16px

      h5
        color #A6A6A6
        font-size: 14px
        font-weight: 700

      a
        font-size: 14px

  &__logs
    padding: 32px !important
    margin-top: 32px
    height: 300px
    overflow-y scroll

    &::-webkit-scrollbar
      display none

    h5
      color #A6A6A6
      font-size: 14px
      font-weight: 700

    &-items
      margin-top: 18px

      div
        display flex
        align-items center
        color #A6A6A6
        font-size: 14px
        margin-top: 16px

        div
          margin-top: 0

        &:first-child
          margin-top: 0

        img
          border-radius 12px
          width: 32px
          height: 32px
          margin-right: 8px

        span
          margin-left: 10px
          display block
          background #f5f5f5
          color #c9c9c9
          padding: 8px 16px
          border-radius 12px

        .green-span
          background rgba(#00C875, 0.2)
          color #00C875

        .red-span
          background rgba(#CC3333, 0.2)
          color #CC3333

        .yellow-span
          background rgba(#FDAB3D, 0.2)
          color #FDAB3D

@media screen and (max-width: $break-point-1700)
  .customer
    &__right-content
      margin-left: 16px
      min-width 250px
      width: 250px

    &__logs
      padding: 20px !important

      &-item
        justify-content space-between

        img
          width: 42px !important
          height: 42px !important

        div
          display flex
          flex-direction column-reverse
          align-items center
          justify-content center

          span
            margin-left: 0
            margin-bottom: 8px

    &__data-customer
      &-title-and-edit
        h5
          font-size: 13px

      &-section
        padding: 20px

        p
          font-size: 15px

    &__table
      .table
      td
        font-size: 15px
        min-width 72px

        &:first-child
          padding: 12px


</style>
