<template>
    <div>
        <div v-if="isPopupOperator" @click="$emit('closePopup')" class="mask"></div>
        <transition name="popup">
            <div v-if="isPopupOperator" class="popup">
                <button @click="$emit('closePopup')" class="popup__close-btn"></button>
                <h2 v-if="isPopupEditOperator">Operator bearbeiten</h2>
                <h2 v-else>Operator erstellen</h2>
                <div>
                    <base-field-input label="NAME">
                        <input v-model="operator.operator_name" type="text" placeholder="Name">
                    </base-field-input>
                </div>
                <div class="create-customer__block_65_35">
                    <div>
                        <base-field-input label="Straße">
                            <input v-model="operator.operator_street" type="text" placeholder="Straße">
                        </base-field-input>
                    </div>
                    <div>
                        <base-field-input label="HNr.">
                            <input v-model="operator.operator_house_number" type="text" placeholder="HNr.">
                        </base-field-input>
                    </div>
                </div>
                <div class="create-customer__block_35_65">
                    <div>
                        <base-field-input label="PLZ">
                            <input v-model="operator.operator_postcode" type="text" placeholder="PLZ">
                        </base-field-input>
                    </div>
                    <div>
                        <base-field-input label="Ort">
                            <input v-model="operator.operator_city" type="text" placeholder="Ort">
                        </base-field-input>
                    </div>
                </div>
                <div>
                    <base-field-input label="MOBIL">
                        <input v-model="operator.operator_phone" type="text" placeholder="Mobil">
                    </base-field-input>
                </div>
                <div>
                    <base-field-input label="E-MAIL FIRMA">
                        <input v-model="operator.operator_email" type="text" placeholder="E-mail firma">
                    </base-field-input>
                </div>
                <div>
                    <base-field-input label="E-MAIL PRIVAT">
                        <input v-model="operator.operator_email_private" type="text" placeholder="E-mail privat">
                    </base-field-input>
                </div>
                <div>
                    <base-field-input label="FAX">
                        <input v-model="operator.operator_fax" type="text" placeholder="Fax">
                    </base-field-input>
                </div>
                <div class="popup__group-btn">
                    <a @click.prevent="$emit('closePopup')" href="#">Cancel</a>
                    <button v-if="isPopupEditOperator" @click.prevent="$emit('editOperator')" class="blue-button">
                        <span></span>
                        bearbeiten
                    </button>
                    <button v-else @click.prevent="$emit('createOperator')" class="blue-button">
                        <span></span>
                        erstellen.
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import fieldErrorRequired from '@/components/fieldErrorRequired.vue'


export default {
    name: "createOperator",
    components: {BaseFieldInput, fieldErrorRequired},
    props: {
        operator: {
            operator_name: String,
            operator_street: String,
            operator_house_number: String,
            operator_postcode: String,
            operator_city: String,
            operator_email: String,
            operator_email_private: String,
            operator_phone: String,
            operator_fax: String
        },
        isPopupOperator: Boolean,
        isPopupEditOperator: Boolean,
    },
}
</script>

<style lang="stylus">

</style>
