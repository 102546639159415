import { render, staticRenderFns } from "./createCustomer.vue?vue&type=template&id=f06a5276&"
import script from "./createCustomer.vue?vue&type=script&lang=js&"
export * from "./createCustomer.vue?vue&type=script&lang=js&"
import style0 from "./createCustomer.vue?vue&type=style&index=0&id=f06a5276&prod&lang=stylus&"
import style1 from "./createCustomer.vue?vue&type=style&index=1&id=f06a5276&prod&lang=stylus&"
import style2 from "./createCustomer.vue?vue&type=style&index=2&id=f06a5276&prod&lang=stylus&"
import style3 from "./createCustomer.vue?vue&type=style&index=3&id=f06a5276&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports